// types
import { ConfigProps } from "types/config";

export const AWS_API = {
  poolId: process.env.REACT_APP_POOL_ID!,
  appClientId: process.env.REACT_APP_CLIENT_ID!,
};

export const AWS_RDS = {
  poolId: process.env.REACT_APP_POOL_ID!,
  appClientId: process.env.REACT_APP_CLIENT_ID!,
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

export const ROOT_PATH = "/dashboard";

export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "light", // light, dark
  presetColor: "theme1", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
  layout: "vertical",
  drawerType: "default",
};

export default config;
