// material-ui
import {
  // Checkbox,
  // FormControl,
  ListSubheader,
  // MenuItem,
  // Select,
  Stack,
  Typography,
} from "@mui/material";

// joy-ui
import { Select, MenuItem, FormControl, Checkbox, Option } from "@mui/joy";

// React-Query
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api_get_account_properties } from "api-config/api-calls/axios_account";
import { api_get_account_list } from "api-config/api-calls/axios_user";
import useAccounts from "hooks/useAccounts";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { ACCESS_LEVEL, getAccessLevel } from "utils/access";

// notification status options

// ==============================|| PROPERTY SELECTION ||============================== //

const PropertySelection = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  // --- useAccounts ---
  const { onChangeAccountProperty, accountID, propertyID } = useAccounts();
  // --- React-Query ---
  const propertyList = useQuery(["propertyList", accountID], () =>
    api_get_account_properties({ accountID })
  );

  const accountList = useQuery(["accountList", user?.id || ""], () =>
    api_get_account_list({
      userID: user?.id || "",
      query: { includeProperty: true, includeOwnership: true },
    })
  );

  // const qryAll = [propertyList];
  // // no account or property set (required!)
  // // page loading
  // // if (qryAll.some(e => e.isLoading)) return <LoadingScreen />
  // // fallback error
  // if (qryAll.some((e) => e.isError)) {
  //   console.log("err getting properties");
  //   ;
  //   // const err = qryAll.find(e => e.error).error;
  //   // return <Error errMsg={err.message} errStatus={err.response.status} />
  // }

  useEffect(() => {}, [propertyList?.data]);
  // -------------------

  const handleChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    const propID = newValue;

    const account = accountList?.data?.find((account) =>
      account?.properties?.some((property) => property.ID === propID)
    );

    const accountID = account?.ID;
    const role = account?.Role;

    // var agStockSystem = propertyList?.data?.find((obj) => obj.ID === propID)?.agStockSystem;
    // ;
    console.log(
      "property selection handler |account | propid",
      accountID,
      propID,
      !!accountID || !!propID
    );
    if (!accountID || !propID) return;
    onChangeAccountProperty({
      accountID: accountID,
      propertyID: propID,
      access: getAccessLevel(role),
    });
    queryClient.invalidateQueries({ queryKey: ["accountData"] });
    queryClient.invalidateQueries({ queryKey: ["propertyData"] });
    // window.location.reload();
  };

  return (
    <FormControl sx={{ height: "80%", mr: 3 }}>
      {/* <InputLabel id="demo-simple-select-label">"</InputLabel> */}
      <Select
        // height={10}
        // labelId="select-property-label"
        id="select-property"
        value={propertyList?.data?.find((property) => property?.ID === propertyID)?.ID || ""}
        // label=""
        onChange={(e, val) => handleChange(e, val)}
        disabled={!accountList?.data}
        placeholder="Select Property"
        color="primary"
      >
        {accountList?.isError ? (
          <Option value="null">Error</Option>
        ) : accountList?.isLoading || propertyList?.data?.length == 0 ? (
          <Option value="null">Loading...</Option>
        ) : accountList?.data?.length > 0 ? (
          accountList?.data?.map((account, i) =>
            // <ListSubheader>{account?.accountName}</ListSubheader>
            account?.properties?.map((property, i) => {
              const menuItem = (
                <Option
                  key={property.ID}
                  value={property.ID}
                  // selected={propertyID === property.ID}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox sx={{ p: 0 }} color="primary" checked={propertyID === property.ID} />
                    <Typography>{property.propertyName}</Typography>
                  </Stack>
                </Option>
              );
              if (i === 0) return [<ListSubheader>{account?.accountName}</ListSubheader>, menuItem];
              return menuItem;
            })
          )
        ) : (
          <Option value="null">No Accounts</Option>
        )}
      </Select>
    </FormControl>
  );
};

export default PropertySelection;
