import { Close, KeyboardArrowRight, Support, VolunteerActivism } from "@mui/icons-material";
import LoomEmbed from "components/elements/loom";
import React, { useMemo, useState } from "react";

// JOY UI
import {
  Button,
  Card,
  Dropdown,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  Stack,
  Typography,
} from "@mui/joy";
import { useLocation } from "react-router-dom";
import { find_video_urls } from "utils/constants/support/video_support_urls";
import ReferModal from "on-load/modalChecks/ReferModal";

function HelpModule(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = React.useCallback(
    (event: React.SyntheticEvent | null, isOpen: boolean) => {
      setOpen(isOpen);
    },
    []
  );

  // get the current page route using useLocation
  const pageRoute = useLocation().pathname;

  const videoUrlObj = useMemo(() => {
    if (!!pageRoute)
      return find_video_urls({
        pageRoute,
      });
  }, [pageRoute]);

  return (
    <Dropdown open={open} onOpenChange={handleOpenChange}>
      <MenuButton
        id="video-center-button"
        variant="outlined"
        color="primary"
        startDecorator={<VolunteerActivism />}
      >
        Refer AgX
      </MenuButton>
      <Menu
        id="mini-support-dropdown"
        // max sure it's not larger than 500px
        sx={{
          maxWidth: 500,
        }}
      >
        <Grid container padding={2} py={1} spacing={2} height={"100%"} justifyContent="center">
          <Grid xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography level="title-lg">Help us out</Typography>
              {/* <Tooltip title="Fullscreen">
                <IconButton>
                  <Fullscreen />
                </IconButton>
              </Tooltip> */}
              <IconButton
                onClick={(e) => {
                  handleOpenChange(e, false);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </Grid>
          {/* <DocsSection videoUrlObj={videoUrlObj} /> */}
        </Grid>
      </Menu>
      <ReferModal open={open} setOpen={setOpen}></ReferModal>
    </Dropdown>
  );
}

export default HelpModule;
