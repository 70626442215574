import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ButtonGroup, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from "@mui/joy";
import { Divider } from "@mui/material";
import AgMesh from "assets/images/AgMesh.png";
import { useSnackbar } from "notistack";
import {
  api_get_account_data,
  api_get_account_mobs,
  api_get_account_referal_code,
  api_get_referal_log,
  api_post_referal_invite,
} from "api-config/api-calls/axios_account";
import useAccounts from "hooks/useAccounts";
import { ArrowForward } from "@mui/icons-material";
import { api_get_property_data } from "api-config/api-calls/axios_property";
import useAuth from "hooks/useAuth";
import { api_get_account_list } from "api-config/api-calls/axios_user";
import ReferalEmailFormStack from "./ReferalEmailFormStack";
import axios from "axios";
import { DownloadJSON } from "utils/tools/helperFunc";
import { ACCESS_LEVEL } from "utils/access";

const EndOfTrial = (props) => {
  const { open, setOpen } = props;
  const [referalCount, setReferalCount] = React.useState(0);
  const { accountID, onChangeAccount, onChangeProperty, propertyID } = useAccounts();
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);

  const referalLog = useQuery(["referalLog", accountID], () => api_get_referal_log({ accountID }), {
    enabled: !!accountID,
  });

  // Mob
  const currLivestockData = useQuery(["currLivestockData", propertyID], () =>
    api_get_account_mobs({ accountID, query: { propertyID, isCurrent: true } })
  );

  // Property
  const propertyData = useQuery(["propertyData", propertyID], () =>
    api_get_property_data({ propertyID })
  );

  const [data, setData] = React.useState<{
    email: string;
    status: "initial" | "loading" | "failure" | "sent";
  }>({
    email: "",
    status: "initial",
  });

  const queryClient = useQueryClient();
  const { user } = useAuth();

  const accountList = useQuery(["accountList", user?.id || ""], () =>
    api_get_account_list({
      userID: user?.id || "",
      query: { includeProperty: true, includeOwnership: true },
    })
  );

  const accountData = useQuery(
    ["accountData", accountID],
    () => api_get_account_data({ accountID }),
    {
      enabled: !!accountID,
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const sendReferalInvite = useMutation((req: any) => api_post_referal_invite(req), {
    onSuccess: (data, variables, context) => {
      enqueueSnackbar("Email sent", { variant: "success" });
      setData({ email: "Waiting...", status: "initial" });
    },
    onError: () => {
      enqueueSnackbar("Error Responding", { variant: "error" });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: "loading" }));
    try {
      //api_send_referal_invite;
      sendReferalInvite.mutate({
        body: {
          email: data.email,
          accountID: accountID,
          shareCodeUsed: accountData?.data?.shareCode,
        },
      });
    } catch (error) {
      setData((current) => ({ ...current, status: "failure" }));
    }
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => {}}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Box sx={{ position: "absolute", top: 8, right: 8 }}>
            <Button
              variant="outlined"
              loading={downloadInProgress}
              onClick={() => {
                setDownloadInProgress(true);

                //
                if (currLivestockData?.data) {
                  DownloadJSON(currLivestockData.data, "mobs-raw");
                }

                if (propertyData?.data) {
                  DownloadJSON(propertyData.data, "property-raw");
                }
                setDownloadInProgress(false);
              }}
            >
              Download your data
            </Button>
          </Box>
          <Typography id="nested-modal-title" level="h2">
            Your 2 month trial for <em>{accountData?.data?.accountName}</em> has ended...
          </Typography>
          <Box sx={{ width: "60vw", borderRadius: 10, mb: 4 }}>
            <Grid container sx={{}}>
              <Grid container>
                <Grid xs={5.5}>
                  {/* Gold color for the left box */}
                  <Box sx={{ bgcolor: "#f5f5f5", borderRadius: 3, height: "100%", m: 2, p: 2 }}>
                    <Typography level="h3">Pay for the Software</Typography>
                    <Typography level="body-sm">
                      Paid annually, you get all of the AgX features. No cap on users, no cap on the
                      number of animals. You own the data.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                      <Stack sx={{ alignItems: "center" }}>
                        <Typography level="h1" textColor="grey">
                          $50/month
                        </Typography>
                        <Typography level="body-sm">Unlimited users, mobs etc</Typography>
                        <Button endDecorator={<ArrowForward />} sx={{ m: 1, p: 1 }}>
                          Pay for AgX
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography level="h1">OR</Typography>
                  </Box>
                </Grid>
                <Grid xs={5.5}>
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      borderRadius: 3,
                      height: "100%",
                      m: 2,
                      p: 2,
                    }}
                  >
                    {referalCount == 0 && <Typography level="h3">Refer 3 Mates</Typography>}
                    {referalCount > 0 && referalCount < 3 && (
                      <Typography level="h3">Refer {3 - referalCount} more mates</Typography>
                    )}
                    {referalCount == 3 && (
                      <Typography level="h3">You've earnt AgX for free</Typography>
                    )}

                    <Typography level="body-sm">
                      If you have liked AgX, and you think its worth sharing, refer us to your
                      mates.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                      <Stack sx={{ alignItems: "center" }}>
                        {/* <Typography level="h1">HG3H-HAKS</Typography>
                        <Typography level="body-sm">Referal Code</Typography> */}
                        <Typography level="h1" textColor="grey">
                          {accountData?.data?.shareCode}
                        </Typography>

                        <Box
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <ReferalEmailFormStack
                            referalLog={referalLog}
                            accountData={accountData}
                            isBig={false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button variant="solid" color="primary" onClick={() => setOpen(false)}>
              Great!
            </Button>
          </Box> */}
          {accountList?.data?.length > 1 && (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                gap: 1,
                flexDirection: { xs: "column", sm: "row-reverse" },
              }}
            >
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                  setOpen(false);
                  onChangeAccount({ account: null, access: ACCESS_LEVEL.DENIED });
                  onChangeProperty(null);
                }}
              >
                Change Account
              </Button>
            </Box>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default EndOfTrial;
