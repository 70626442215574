import PropTypes from "prop-types";
import { createContext } from "react";
import { ACCESS_LEVEL } from "utils/access"; // project import
import useLocalStorage from "hooks/useLocalStorage";
import axios from "axios";

// initial state
const initialState = {
  globalLoadingPage: false,
  accountID: null,
  propertyID: null,
  access: null,
  // agStockSystem: null,
  onChangeAccount: (_val) => {},
  onChangeProperty: (_val) => {},
  // onChangeAgStockSystem: () => { },
  onChangeAccountProperty: (_val) => {},
  onCheckAccess: (_val) => {},

  // onSetPropertyList: () => { },
  // loadReportData: () => {},
  // loadAccountData: () => {},
  // loadAccountList: () => {},
  setGLP: () => {},
  logoutAccountData: () => {},
};

// ==============================|| acc CONTEXT & PROVIDER ||============================== //

const AccountContext = createContext(initialState);

function AccountProvider({ children }) {
  const [acc, setAcc] = useLocalStorage("agx-accounts", {
    accountID: initialState.accountID,
    propertyID: initialState.propertyID,
    access: initialState.access,
    // agStockSystem: initialState.agStockSystem,
    globalLoadingpage: initialState.globalLoadingPage,
  });

  const onChangeAccount = ({ accountID, access }) => {
    setAcc({
      ...acc,
      accountID: accountID,
      access: access,
    });
  };

  const onCheckAccess = ({ access }) => {
    setAcc({
      ...acc,
      access: access,
    });
  };

  const onChangeAccountProperty = ({ accountID, propertyID, access }) => {
    // ;
    setAcc({
      ...acc,
      propertyID: propertyID,
      accountID: accountID,
      access: access,
    });
  };

  const onChangeProperty = (propertyID) => {
    setAcc({
      ...acc,
      propertyID: propertyID,
    });
  };

  // const onChangeAgStockSystem = ({ agStockSystem }) => {
  //   setAcc({
  //     ...acc,
  //     agStockSystem,
  //     //   propertyID: propertyID,
  //   });
  // };

  // const loadAccountData = ({ userID, accountID, GLP = true }) => {
  //   return new Promise((resolve, reject) => {
  //     setGLP(GLP);
  //     axios
  //       .post(process.env.REACT_APP_SERVER_URL + `/db/account-data`, {
  //         userID: userID,
  //         accountID: accountID,
  //         request: ["all"],
  //       })
  //       .then(async (res) => {
  //         try {
  //           // ;
  //           localStorage.setItem("account-data", JSON.stringify(res.data));
  //           setGLP(false);
  //           resolve(res.data);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       });
  //   });
  // };

  // const loadAccountList = ({ userID }) => {
  //   setGLP(true);
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(process.env.REACT_APP_SERVER_URL + `/db/account-data`, {
  //         userID: userID,
  //         accountID: null,
  //         request: ["account-list"],
  //       })
  //       .then(async (res) => {
  //         try {
  //           // ;
  //           localStorage.setItem("account-list", JSON.stringify(res.data));
  //           setGLP(false);
  //           resolve(res.data);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       });
  //   });
  // };

  // const loadReportData = ({ userID, accountID, GLP = true }) => {
  //   return new Promise((resolve, reject) => {
  //     ;
  //     setGLP(GLP);
  //     axios
  //       .post(process.env.REACT_APP_SERVER_URL + `/db/account-data`, {
  //         userID: userID,
  //         accountID: accountID,
  //         request: ["reportData"],
  //       })
  //       .then(async (res) => {
  //         try {
  //           // ;
  //           console.log(
  //             "report data res",
  //             res.data,
  //             res.data[accountID].reportData
  //           );
  //           localStorage.setItem(
  //             "report-data",
  //             JSON.stringify(res.data[accountID].reportData)
  //           );
  //           setGLP(false);
  //           resolve(res.data[accountID].reportData);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       });
  //   });
  // };

  const setGLP = (globalLoadingPage) => {
    setAcc({
      ...acc,
      globalLoadingPage,
    });
  };

  const logoutAccountData = () => {
    setAcc({
      ...acc,
      accountID: initialState.accountID,
      propertyID: initialState.propertyID,
      access: initialState.access,
    });
  };

  // const getDeviceData = ({ userID, accountID, propertyID }) => {};

  return (
    <AccountContext.Provider
      value={{
        ...acc,
        onChangeAccount,
        onChangeProperty,
        onChangeAccountProperty,
        onCheckAccess,
        // onChangeAgStockSystem,
        // loadReportData,
        // loadAccountData,
        // loadAccountList,
        setGLP,
        logoutAccountData,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.node,
};

export { AccountProvider, AccountContext };
