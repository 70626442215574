import useAccounts from "hooks/useAccounts";
import React, { useEffect, useState } from "react";

// mui-joy
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";

import { useQuery } from "@tanstack/react-query";
import { api_get_account_list } from "api-config/api-calls/axios_user";
import useAuth from "hooks/useAuth";
import { ACCESS_LEVEL, getAccessLevel } from "utils/access";
import { access } from "fs";

const PropertyAccountGuard = ({ children }) => {
  // const { setManualAccountCreate } = props;
  const { accountID, propertyID, onChangeAccount, onChangeProperty } = useAccounts();
  const { user } = useAuth();

  const [tempPropertyID, setTempPropertyID] = useState("");

  const accountList = useQuery(
    ["accountList", user?.id || ""],
    () =>
      api_get_account_list({
        userID: user?.id || "",
        query: { includeProperty: true, includeOwnership: true },
      }),
    {
      enabled: !!user?.id,
    }
  );

  useEffect(() => {
    if (!!accountID || !!propertyID) return;
    if (!accountList.isLoading) {
      const accountListLength = accountList?.data?.length;
      if (accountListLength == 0) return;
      if (accountListLength == 1 && accountList?.data?.[0]?.properties?.length > 0) {
        onChangeAccount({
          accountID: accountList?.data?.[0].ID,
          access: getAccessLevel(accountList?.data?.[0].Role),
        });
        onChangeProperty(accountList?.data?.[0]?.properties?.[0]?.ID);
      }

      if (!accountID) {
        // onChangeAccount(accountList?.data?.[0].ID);
        const firstAccountWithProperty = accountList?.data?.find(
          (account) => account?.properties?.length > 0
        );

        if (!firstAccountWithProperty) return;

        const account = accountList?.data.find(
          (account) => account.ID === firstAccountWithProperty.ID
        );
        onChangeAccount({
          accountID: firstAccountWithProperty.ID,
          access: getAccessLevel(account?.Role),
        });

        setTempPropertyID(firstAccountWithProperty?.properties?.[0]?.ID);
      } else if (!propertyID) {
        const firstProperty = accountList?.data?.find((account) => account.ID === accountID)
          ?.properties?.[0]?.ID;
        if (!firstProperty) return;
        setTempPropertyID(firstProperty);
      }
      // if (
      //   !propertyID &&
      //   !!accountID &&
      //   accountList?.data?.find((acc) => acc.ID === accountID)?.properties?.length === 0
      // ) {
      //   setTempPropertyID(accountList?.data?.[0]?.properties[0]?.ID);
      // }
    }
  }, [accountID, propertyID, accountList?.data]);

  return !accountID || !propertyID ? (
    <div>
      <Card>
        <Modal open={!accountList.isLoading && (!accountID || !propertyID)} onClose={() => {}}>
          <ModalDialog>
            <DialogTitle>Choose Account</DialogTitle>
            <DialogContent>Select which account and property.</DialogContent>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Account</FormLabel>
                <Select
                  onChange={(e, val) => {
                    const account = accountList?.data.find((account) => account.ID === val);
                    onChangeAccount({ accountID: val, access: getAccessLevel(account?.Role) });
                  }}
                  value={accountID}
                  disabled={accountList?.isLoading || accountList?.data?.length === 1}
                  placeholder={accountList?.isLoading ? "Loading..." : ""}
                >
                  {accountList?.data?.map((account) => (
                    <Option key={account.ID} value={account.ID}>
                      {account.accountName}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl disabled={!accountID}>
                <FormLabel>Property</FormLabel>
                <Select
                  placeholder={
                    accountList?.data?.isLoading
                      ? "Loading..."
                      : accountList?.data?.filter((account) => account.ID === accountID)?.[0]
                          ?.properties?.length > 0
                      ? ""
                      : "No properties exist"
                  }
                  disabled={
                    accountList?.isLoading ||
                    accountList?.data?.filter((account) => account.ID === accountID)?.[0]
                      ?.properties?.length === 0
                  }
                  onChange={(e, val) => {
                    setTempPropertyID(val || "");
                  }}
                  value={!!propertyID ? propertyID : tempPropertyID}
                >
                  {accountList?.data
                    ?.filter((account) => account.ID === accountID)?.[0]
                    ?.properties?.map((property) => (
                      <Option key={property.ID} value={property.ID}>
                        {property.propertyName}
                      </Option>
                    ))}
                </Select>
              </FormControl>
              <Button
                onClick={() => {
                  console.log(
                    "entering property | acc | real | tmp",
                    accountID,
                    propertyID,
                    tempPropertyID
                  );
                  onChangeProperty(tempPropertyID);
                }}
                disabled={!!!tempPropertyID}
              >
                Enter
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  // setManualAccountCreate(true);
                }}
              >
                Create new account
              </Button>
            </Stack>
          </ModalDialog>
        </Modal>
      </Card>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default PropertyAccountGuard;
