// third-party
import { FormattedMessage } from "react-intl";

// MUI IMPORT ICONS
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Dashboard from "@mui/icons-material/Dashboard";
import Landscape from "@mui/icons-material/Landscape";
import Map from "@mui/icons-material/Map";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import Pets from "@mui/icons-material/Pets";
import ScheduleOutlined from "@mui/icons-material/ScheduleOutlined";
import Sensors from "@mui/icons-material/Sensors";
// ---

// assets
import {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDrone,
  IconClipboardList,
  IconUserCheck,
} from "@tabler/icons";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDrone,
  Landscape,
  Dashboard,
  CalendarMonth,
  Sensors,
  Map,
  IconClipboardList,
  IconUserCheck,
  PeopleAltOutlined,
  ScheduleOutlined,
  Pets,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const mainSection = {
  id: "mainSection",
  title: <FormattedMessage id="Analytics" />,
  // caption: <FormattedMessage id="Main Menu" />,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.Dashboard,
      breadcrumbs: false,
    },
    {
      id: "aerial",
      title: <FormattedMessage id="Aerial" />,
      type: "item",
      url: "/aerial",
      icon: icons.Map,
      breadcrumbs: false,
    },
  ],
};

export default mainSection;
