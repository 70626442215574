type VideoObj = {
  // ID: string;
  url: string;
  title: string;
  description: string;
  tags: string[];
  routes: string[];
  instructionalVideo: boolean;
};

type FindVideoUrlProps = {
  pageRoute: string;
};

const NUMBER_OF_VIDEOS_TO_SHOW = 3;

export const find_video_urls = (props: FindVideoUrlProps) => {
  const { pageRoute } = props;
  const video_urls = video_urlObj
    .filter((video) => video.routes.includes(pageRoute) && video.instructionalVideo)
    // find closest matched videos
    .sort((a, b) => {
      const aLength = a.routes.length;
      const bLength = b.routes.length;
      if (aLength > bLength) return -1;
      if (aLength < bLength) return 1;
      return 0;
    })
    .sort((a, b) => {
      const aLength = a.tags.length;
      const bLength = b.tags.length;
      if (aLength > bLength) return -1;
      if (aLength < bLength) return 1;
      return 0;
    })
    ?.slice(0, NUMBER_OF_VIDEOS_TO_SHOW + 1);
  return video_urls;
};

export const video_urlObj: VideoObj[] = [
  {
    url: "https://www.loom.com/share/e139204aba2244a2b372e9862916d282?sid=ba19f30d-5102-4ce1-a818-3eae2f5fe141",
    title: "Add a paddock",
    description: "This video will show you how to add a property",
    tags: ["paddock", "map", "draw", "short"],
    routes: ["/aerial"],
    instructionalVideo: false,
  },
  {
    url: "https://www.loom.com/share/c0beb6571f7940c3b6b5378927784a61?sid=d088ec2b-708c-4ebd-867b-586d758342b5",
    title: "Add Mobs",
    description: "This video will show you how to add a property",
    tags: ["mobs", "agstock", "get started"],
    routes: ["/livestock"],
    instructionalVideo: true,
  },
  {
    url: "https://www.loom.com/share/efd51efd79af43af9ce1f0580e87c66b?sid=0aa45630-4c92-4a46-974b-ef4cf5b28926",
    title: "Edit Devices",
    description: "This video will show you how to edit your devices.",
    tags: ["devices", "agmesh", "get started"],
    routes: ["/aerial", "/dashboard", "/devices"],
    instructionalVideo: true,
  },
];
