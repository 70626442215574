// third-party
import { FormattedMessage } from "react-intl";

// MUI IMPORT ICONS
import Landscape from "@mui/icons-material/Landscape";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import Settings from "@mui/icons-material/Settings";
import Storage from "@mui/icons-material/Storage";
// ---

// constant
const icons = {
  PeopleAltOutlined,
  Landscape,
  Settings,
  Storage,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const managementSection = {
  id: "management",
  title: <FormattedMessage id="Management" />,
  // caption: <FormattedMessage id="Management" />,
  type: "group",
  children: [
    // {
    //   id: "safetyDocuments",
    //   title: <FormattedMessage id="Documents" />,
    //   type: "item",
    //   url: "/documents",
    //   icon: icons.Storage,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "properties",
    //   title: <FormattedMessage id="Properties" />,
    //   type: "item",
    //   url: "/properties",
    //   icon: icons.Landscape,
    //   breadcrumbs: false,
    // },
    {
      id: "management",
      title: <FormattedMessage id="Users" />,
      type: "item",
      url: "/user-management",
      icon: icons.PeopleAltOutlined,
      breadcrumbs: false,
    },

    {
      id: "settings",
      title: <FormattedMessage id="Settings" />,
      type: "item",
      url: "/settings",
      icon: icons.Settings,
      breadcrumbs: false,
    },
  ],
};

export default managementSection;
