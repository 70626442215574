// ----------------- //
//  USER API
// ----------------- //
// User API requests

// --- IMPORTS
// axios
import axios from "axios";
// react-query
import {
  BASE_API_URL,
  API_CONFIG_GET,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  API_CONFIG_PATCH,
  API_CONFIG_DEL,
} from "api-config/api-helper";
import { applyAuthTokenInterceptor } from "api-config/api-helper/axios_interceptor";

// --- CONFIG
const LCL_URL = "users/";
const axiosInstance = axios.create({
  baseURL: BASE_API_URL + LCL_URL,
});

applyAuthTokenInterceptor(axiosInstance);

// --- REQUESTS
// Get onboard list
const api_get_onboard_requests = ({ query }) => {
  const FNC_URL = "onboard";
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// Get Account List
const api_get_account_list = ({ userID, query }) => {
  const FNC_URL = "account_list/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
        isGodMode: process.env.REACT_APP_DEVELOPMENT_GOD_MODE,
      },
    })
    .then((res) => res.data);
};

const api_post_invite_user = ({ body }) => {
  const FNC_URL = "onboard";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

const api_post_add_user = ({ body }) => {
  const FNC_URL = "existing";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

const api_post_remove_user = ({ userID, body }) => {
  const FNC_URL = "existing/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_DEL,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

const api_post_revoke_user = ({ body }) => {
  const FNC_URL = "onboard";
  return axiosInstance
    .request({
      ...API_CONFIG_DEL,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

const api_patch_update_user = ({ userID, body }) => {
  const FNC_URL = "existing/update/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_PATCH,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

const api_post_user_avatar = ({ formData }) => {
  const FNC_URL = "avatar";
  return axiosInstance.request({
    ...API_CONFIG_POST,
    url: FNC_URL,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export {
  api_get_account_list,
  api_post_invite_user,
  api_get_onboard_requests,
  api_post_add_user,
  api_post_revoke_user,
  api_post_remove_user,
  api_patch_update_user,
  api_post_user_avatar,
};
