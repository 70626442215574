import { useState, useEffect } from "react";

import WhatsNewManager from "./modalChecks/WhatsNewManager";
import ReferModal from "./modalChecks/ReferModal";
import EndOfTrial from "./modalChecks/EndOfTrial";
import SuccessfulPayment from "./modalChecks/SuccessfulPayment";
import SuccessfulReferal from "./modalChecks/SuccessfulReferal";
import useAccounts from "hooks/useAccounts";
import { useQuery } from "@tanstack/react-query";
import {
  api_get_account_data,
  api_get_account_referal_code,
} from "api-config/api-calls/axios_account";
import { api_get_account_list } from "api-config/api-calls/axios_user";
import useAuth from "hooks/useAuth";
import { getAccessLevel } from "utils/access";
const SubscriptionHandler = ({ children }) => {
  const { user } = useAuth();
  const { accountID, propertyID, onCheckAccess } = useAccounts();
  const [referalModalOpen, setReferalModalOpen] = useState(false);
  const [referalCount, setReferalCount] = useState(0);
  const [whatsNewModalOpen, setWhatsNewModalOpen] = useState(false);
  const [endOfTrialOpen, setEndOfTrialOpen] = useState(false);
  const [successfulPaymentOpen, setSuccessPaymentModalOpen] = useState(false);
  const [successfulReferalOpen, setSuccessfulReferalOpen] = useState(false);

  const accountReferalCount = useQuery(
    ["referalCount", accountID],
    () => api_get_account_referal_code({ accountID }),
    {
      enabled: !!accountID,
    }
  );

  const accountData = useQuery(
    ["accountData", accountID],
    () => api_get_account_data({ accountID }),
    {
      enabled: !!accountID,
    }
  );

  const accountList = useQuery(
    ["accountList", user?.id || ""],
    () =>
      api_get_account_list({
        userID: user?.id || "",
        query: { includeProperty: true, includeOwnership: true },
      }),
    {
      enabled: !!user?.id,
    }
  );

  useEffect(() => {
    if (accountList.data) {
      const account = accountList?.data.find((account) => account.ID === accountID);
      console.log("SETTING HERE", account?.Role, getAccessLevel(account?.Role));

      onCheckAccess({ access: getAccessLevel(account?.Role) });
    }
  }, [accountList.data]);

  const currentDate = new Date();

  const twoMonthsAgo = new Date(currentDate);
  twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

  useEffect(() => {
    if (accountReferalCount.data) {
      setReferalCount(accountReferalCount.data.length);
    }
  }, [accountReferalCount.data]);

  useEffect(() => {
    if (accountID) {
      // Condition for if they have paid: 2 months have gone by,
      console.log("PAID CHECK INITIATED");
      if (accountData.data && propertyID && accountReferalCount?.data) {
        console.log("PAID CHECK DATA THERE");
        if (
          accountReferalCount.data.length < 3 &&
          new Date(accountData.data.dateCreated) < twoMonthsAgo &&
          !accountData.data.isPaid
        ) {
          console.log("SHOULD SHOW");

          if (endOfTrialOpen === false) {
            setEndOfTrialOpen(true);
          }
        }
      }
    }
  }, [accountID, accountReferalCount.isLoading, propertyID, accountData.isLoading]);

  return (
    <>
      {children}
      <WhatsNewManager open={whatsNewModalOpen} setOpen={setWhatsNewModalOpen}></WhatsNewManager>
      <ReferModal open={referalModalOpen} setOpen={setReferalModalOpen}></ReferModal>
      <EndOfTrial
        open={endOfTrialOpen}
        setOpen={setEndOfTrialOpen}
        accountData={accountData}
      ></EndOfTrial>
      <SuccessfulPayment
        open={successfulPaymentOpen}
        setOpen={setSuccessPaymentModalOpen}
      ></SuccessfulPayment>
      <SuccessfulReferal
        open={successfulReferalOpen}
        setOpen={setSuccessfulReferalOpen}
      ></SuccessfulReferal>
    </>
  );
};

export default SubscriptionHandler;
