// third-party
import { FormattedMessage } from "react-intl";
// MUI IMPORT ICONS
import Sensors from "@mui/icons-material/Sensors";
import Store from "@mui/icons-material/Store";

// ---

// constant
const icons = {
  Sensors,
  Store,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const agMeshSection = {
  id: "agMeshSection",
  title: <FormattedMessage id="AgMesh" />,
  // caption: <FormattedMessage id="Livestock" />,
  type: "group",
  children: [
    {
      id: "devices",
      title: <FormattedMessage id="Devices" />,
      type: "item",
      url: "/devices",
      icon: icons.Sensors,
      breadcrumbs: false,
    },
    // {
    //   id: "store",
    //   title: <FormattedMessage id="Store" />,
    //   type: "item",
    //   url: "/store",
    //   icon: icons.Store,
    //   breadcrumbs: false,
    // },
  ],
};

export default agMeshSection;
