// ----------------- //
//  META API
// ----------------- //
// Account API requests

// --- IMPORTS
// axios
import axios from "axios";
// react-query
import { useQuery } from "@tanstack/react-query";
import {
  BASE_API_URL,
  API_CONFIG_GET,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  API_CONFIG_PATCH,
  API_CONFIG_DEL,
} from "api-config/api-helper";
import { applyAuthTokenInterceptor } from "api-config/api-helper/axios_interceptor";

// --- CONFIG
const LCL_URL = "meta";
const axiosInstance = axios.create({
  baseURL: BASE_API_URL + LCL_URL,
});

applyAuthTokenInterceptor(axiosInstance);

// --- REQUESTS
// Get Account Property List

const api_post_product_recommendation = ({ body }) => {
  const FNC_URL = "product_recommendation/";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      params: {},
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

const api_get_hubspot_auth = ({ first_name, sub, last_name, email }) => {
  const FNC_URL = "hubspot_auth/" + sub + "/" + first_name + "/" + last_name + "/" + email;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {},
      data: {},
    })
    .then((res) => res.data);
};

const api_check_referal_code = ({ referal_code }) => {
  const FNC_URL = "check_referal_code/" + referal_code;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {},
      data: {},
    })
    .then((res) => res.data);
};

export { api_post_product_recommendation, api_get_hubspot_auth, api_check_referal_code };
