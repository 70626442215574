export enum ACCESS_LEVEL {
  OWNER = 1,
  ADMINISTRATOR = 2,
  MANAGER = 4,
  EMPLOYEE = 8,
  CONTRACTOR = 16,
  DENIED = 36,
}

export const getAccessLevel = (roleString) => {
  // Check if roleString is not provided or falsy
  if (!roleString) {
    return ACCESS_LEVEL.DENIED;
  }

  switch (roleString.toLowerCase()) {
    case "owner":
      return ACCESS_LEVEL.OWNER;
    case "administrator":
      return ACCESS_LEVEL.ADMINISTRATOR;
    case "manager":
      return ACCESS_LEVEL.MANAGER;
    case "employee":
      return ACCESS_LEVEL.EMPLOYEE;
    case "contractor":
      return ACCESS_LEVEL.CONTRACTOR;
    default:
      return ACCESS_LEVEL.DENIED; // or any default value
  }
};

export const getRoleByAccessLevel = (accessLevel) => {
  // Invert the ACCESS_LEVEL to map values to keys
  const roles = Object.keys(ACCESS_LEVEL).reduce((acc, key) => {
    acc[ACCESS_LEVEL[key]] = key;
    return acc;
  }, {});

  // Return the role corresponding to the accessLevel, or 'Unknown' if not found
  const role = roles[accessLevel] || "UNKNOWN";
  return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
};
