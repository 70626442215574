import PropTypes from "prop-types";
import { useState } from "react";

// project imports
import { useQuery } from "@tanstack/react-query";
import { api_get_onboard_requests } from "api-config/api-calls/axios_user";
import useAuth from "hooks/useAuth";

// main import

// joy-ui
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Modal from "@mui/joy/Modal";
import Stack from "@mui/joy/Stack";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Button from "@mui/joy/Button";
import ModalDialog from "@mui/joy/ModalDialog";
import Avatar from "@mui/joy/Avatar";
import ListDivider from "@mui/joy/ListDivider";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ModalClose from "@mui/joy/ModalClose";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";

// project imports

// IMPORT MUI COMPONENTS
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import GroupAdd from "@mui/icons-material/GroupAdd";
// ---

// third-party
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api_post_add_user, api_post_revoke_user } from "api-config/api-calls/axios_user";
import { useSnackbar } from "notistack";
import { capitalizeFL } from "utils/tools/helperFunc";

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const OnboardCheck = ({ children }) => {
  const { user } = useAuth();
  const [cardOpen, setCardOpen] = useState(true);
  const onboardData = useQuery(["onboardData", user?.email], () =>
    api_get_onboard_requests({ query: { email: user?.email } })
  );

  const handleClose = () => {
    setCardOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const handleSuccess = (msg) => {
    enqueueSnackbar(msg, { variant: "success", preventDuplicate: true });
    setIsRejecting(false);
    setIsAccepting(false);
    // temporary fix for now
    // remove card
    setCardOpen(false);
    // handleClose();
  };

  const handleFailure = () => {
    enqueueSnackbar("Error completing request", { variant: "error" });
    setIsAccepting(false);
    setIsRejecting(false);
  };

  const addUser = useMutation(
    (formData: any) => {
      return api_post_add_user(formData);
    },
    {
      onError: (error, variables, context) => {
        console.log(`Mutation error is ${error}`);
        handleFailure();
      },
      onSuccess: (data, variables, context) => {
        queryClient?.setQueryData(["onboardData", user?.email], (oldData) => data);
        queryClient?.invalidateQueries({ queryKey: ["onboardData"] });
        handleSuccess("Success joining account");
      },
    }
  );

  const revokeUser = useMutation(
    (formData: any) => {
      return api_post_revoke_user(formData);
    },
    {
      onError: (error, variables, context) => {
        console.log(`Mutation error is ${error}`);
        handleFailure();
      },
      onSuccess: (data, variables, context) => {
        queryClient?.setQueryData(["onboardData", user?.email], (oldData) => data);
        queryClient?.invalidateQueries({ queryKey: ["onboardData"] });
        handleSuccess("Success revoking request");
      },
    }
  );

  const handleOnboard = (data, rqst) => {
    if (rqst === "accept") {
      setIsAccepting(true);
      addUser.mutate({
        body: {
          email: data.email,
          accountID: data.accountID,
          role: data?.role?.toLowerCase(),
          userID: user?.id,
        },
      });
    } else if (rqst === "reject") {
      setIsRejecting(true);
      revokeUser.mutate({
        body: {
          email: data.email,
          accountID: data.accountID,
          role: data?.role?.toLowerCase(),
        },
      });
    }
  };

  return (
    <>
      <Modal open={cardOpen && onboardData?.data?.length > 0} onClose={handleClose}>
        <ModalDialog>
          <ModalClose />
          <DialogTitle>Account Requests</DialogTitle>
          <DialogContent>You have been requested to join an account.</DialogContent>

          <List sx={{ "--ListItemDecorator-size": "56px" }}>
            {onboardData?.data?.map((onboard) => (
              <>
                <ListItem key={onboard.ID}>
                  <ListItemDecorator>
                    <Avatar variant="solid" alt={onboard.accountName} />
                  </ListItemDecorator>
                  <Stack
                    direction={{
                      xs: "column",
                      md: "row",
                    }}
                    spacing={1}
                    flexGrow={1}
                    alignItems="center"
                  >
                    <Stack direction="column">
                      <Typography level="title-sm">{onboard.accountName}</Typography>
                      <Typography level="body-sm" textAlign="left" maxWidth={270}>
                        {onboard.senderName} requested you to join as&nbsp;
                        {capitalizeFL(onboard.role).slice(0, onboard.role.length - 1)}.
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      height="100%"
                      width={{
                        xs: "100%",
                        md: "auto",
                      }}
                    >
                      <Tooltip title="Reject Account">
                        <Button
                          variant="outlined"
                          color="danger"
                          size="sm"
                          startDecorator={
                            <DeleteOutline sx={{ display: { xs: "none", sm: "block" } }} />
                          }
                          onClick={() => handleOnboard(onboard, "reject")}
                          loading={isRejecting}
                          disabled={isAccepting}
                          fullWidth
                        >
                          Reject
                        </Button>
                      </Tooltip>
                      <Tooltip title="Join Account">
                        <Button
                          variant="outlined"
                          color="success"
                          size="sm"
                          startDecorator={
                            <GroupAdd sx={{ display: { xs: "none", sm: "block" } }} />
                          }
                          onClick={() => handleOnboard(onboard, "accept")}
                          loading={isAccepting}
                          disabled={isRejecting}
                          fullWidth
                        >
                          Join
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </ListItem>
                <ListDivider />
              </>
            ))}
          </List>
        </ModalDialog>
      </Modal>
      {children}
    </>
  );
};

OnboardCheck.propTypes = {
  children: PropTypes.node,
};

export default OnboardCheck;
