import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Mutation, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Stack,
  ButtonGroup,
  Tooltip,
  CircularProgress,
} from "@mui/joy";
import { InfoOutlined, Launch, More } from "@mui/icons-material";
import { useSnackbar } from "notistack";

import {
  api_get_account_data,
  api_get_account_referal_code,
  api_get_referal_log,
  api_post_referal_invite,
} from "api-config/api-calls/axios_account";
import useAccounts from "hooks/useAccounts";
import { width } from "@mui/system";
import useAuth from "hooks/useAuth";
import axios from "axios";

const ReferalEmailFormStack = (props) => {
  const { referalLog, accountData, isBig = true } = props;
  const { accountID } = useAccounts();
  const { user } = useAuth();

  const [sendingInProgress, setSendingInProgress] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const ReferalEmailForm = ({ email, isFirst, status }) => {
    const LAMBDA_EMAILER = String(process.env.REACT_APP_LAMBDA_EMAILER);

    const sendReferalInvite = useMutation((req: any) => api_post_referal_invite(req), {
      onSuccess: (data, variables, context) => {
        enqueueSnackbar("Email sent", { variant: "success" });
        referalLog.refetch();
        setSendingInProgress(false);
      },
      onError: () => {
        enqueueSnackbar("Error Responding", { variant: "error" });
        setSendingInProgress(false);
      },
    });

    const [data, setData] = React.useState({
      email: email || "",
      status: "initial",
      isExisting: false,
      isValid: true,
    });

    const listOfExisting = React.useMemo(() => {
      const emailsAndPhones =
        referalLog && referalLog.data
          ? referalLog.data.reduce((acc, item) => {
              if (item.email) acc.add(item.email);
              if (item.phone) acc.add(item.phone);
              return acc;
            }, new Set())
          : new Set();
      return emailsAndPhones;
    }, [referalLog]);

    // Regex patterns for email and phone number validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    const numbersAndPlus = /^[0-9+]+$/;

    // Function to validate the email or phone
    const isValidInput = (input) => {
      console.log("EMAIL", emailRegex.test(input));
      console.log("PHONE", phoneRegex.test(input));

      return emailRegex.test(input) || phoneRegex.test(input);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setSendingInProgress(true); // Assuming you have a state or a function to manage this

      if (data.isExisting || !isValidInput(data.email)) {
        if (numbersAndPlus.test(data.email)) {
          setData((current) => ({ ...current, status: "failure-phone" }));
        } else {
          setData((current) => ({ ...current, status: "failure" }));
        }
        setSendingInProgress(false); // Make sure to handle the loading state correctly
        return;
      }

      setData((current) => ({ ...current, status: "loading" }));

      try {
        await sendReferalInvite.mutate({
          // Using await to properly handle the async operation
          body: {
            email: data.email,
            isEmail: emailRegex.test(data.email),
            accountID: accountID,
            shareCodeUsed: accountData?.data?.shareCode,
            refererName: `${user?.firstName} ${user?.lastName}`,
          },
        });
        try {
          axios
            .post(
              LAMBDA_EMAILER,
              `NEW REFERAL: - EMAIL: ${data.email} NAME:${user?.firstName} ${user?.lastName}`
            )
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {}
      } catch (error) {
        console.error("Failed to send referral invite:", error);
        setData((current) => ({ ...current, status: "failure" }));
        setSendingInProgress(false);
      }
    };

    // Update for checking existing emails or phones
    const handleInputChange = (event) => {
      console.log("EW VALUNE", event.target.value);
      const newValue = event.target.value;
      // const isExisting = listOfExisting.has(newValue);
      const lowerCaseSet = new Set(Array.from(listOfExisting).map((value) => value.toLowerCase()));
      const isExisting = lowerCaseSet.has(newValue.toLowerCase());

      const isValid = isValidInput(newValue);
      setData({
        email: newValue,
        status: "initial",
        isExisting: isExisting,
        isValid: isValid,
      });
    };

    return (
      <form onSubmit={handleSubmit} id={email} key={email}>
        <FormControl sx={{ mt: 2 }}>
          {isFirst && (
            <FormLabel
              sx={(theme) => ({
                "--FormLabel-color": theme.vars.palette.primary.plainColor,
              })}
            >
              Add your mate's email or phone below
            </FormLabel>
          )}
          <Input
            sx={{ "--Input-decoratorChildHeight": "45px", width: isBig ? "30vw" : "100%" }}
            placeholder={"Enter email or phone here"}
            required
            disabled={status != null}
            value={data.email}
            onChange={handleInputChange}
            error={data.status === "failure" || data.isExisting}
            endDecorator={
              <Button
                variant="solid"
                disabled={data.isExisting}
                color={
                  status === "PENDING"
                    ? "neutral"
                    : status === "APPROVED"
                    ? "success"
                    : status === "ERROR"
                    ? "danger" // Adding the error color condition
                    : "primary"
                }
                loading={data.status === "loading"}
                type="submit"
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                {status === "PENDING"
                  ? "Pending"
                  : status === "APPROVED"
                  ? "Approved"
                  : status === "ERROR"
                  ? "Failed to send" // Changing the button text for error state
                  : "Invite to AgX"}
              </Button>
            }
          />
          {data.status === "failure" && (
            <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[400] })}>
              Oops! Please enter a valid email or phone number.
            </FormHelperText>
          )}
          {data.status === "failure-phone" && (
            <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[400] })}>
              Oops! Please use an area code (+61) with the number. For example, +61412345678
            </FormHelperText>
          )}
          {data.isExisting && (
            <FormHelperText sx={(theme) => ({ color: theme.vars.palette.warning[400] })}>
              You can only refer someone once.
            </FormHelperText>
          )}
          {data.status === "sent" && (
            <FormHelperText sx={(theme) => ({ color: theme.vars.palette.primary[400] })}>
              Sent! Tell them to check their email.
            </FormHelperText>
          )}
        </FormControl>
      </form>
    );
  };

  return (
    <Box sx={{ maxHeight: "40vh", overflow: "scroll" }}>
      <Stack justifyContent="center">
        {referalLog?.data &&
          referalLog.data.map((data, index) => (
            <ReferalEmailForm
              key={index}
              email={data.email !== null ? data.email : data.phoneNumber}
              isFirst={data.isFirst}
              status={data.status}
            />
          ))}
        {referalLog?.data && referalLog.data.length === 0 ? (
          <>
            <ReferalEmailForm email={null} isFirst={false} status={null} />
            <ReferalEmailForm email={null} isFirst={false} status={null} />
            <ReferalEmailForm email={null} isFirst={false} status={null} />
          </>
        ) : referalLog?.data && referalLog.data.length === 1 ? (
          <>
            <ReferalEmailForm email={null} isFirst={false} status={null} />
            <ReferalEmailForm email={null} isFirst={false} status={null} />
          </>
        ) : (
          <ReferalEmailForm email={null} isFirst={false} status={null} />
        )}

        {sendingInProgress && (
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              pt: 2,
              pl: 2, // Padding left
              pr: 2, // Padding right
            }}
            direction="row" // Change to "column" if you want a vertical stack
            spacing={2} // Adjust the spacing between items as needed
          >
            <CircularProgress />
            <Typography>Sending referral now...</Typography>
          </Stack>
        )}

        {/* <ReferalEmailForm email={null} isFirst={false} status={null} />
        <ReferalEmailForm email={null} isFirst={false} status={null} /> */}

        <Box sx={{ height: "30vh" }}></Box>
      </Stack>
    </Box>
  );
};

export default ReferalEmailFormStack;
