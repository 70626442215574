import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { GuardProps } from "types";
import useAccounts from "hooks/useAccounts";
import { api_get_hubspot_auth } from "api-config/api-calls/axios_meta";
import { BrowserTracing } from "@sentry/tracing";

// SENTRY
import * as Sentry from "@sentry/react";
import userflow from "userflow.js";

import { useQuery } from "@tanstack/react-query";
import { api_get_account_list } from "api-config/api-calls/axios_user";

// ==============================|| AUTH GUARD ||============================== //

function initReplay(user) {
  let shouldReplay = 1.0;

  switch (user?.email) {
    case "sam@agxfarm.com":
      shouldReplay = 0.0;
      break;
    case "cal@agxfarm.com":
      shouldReplay = 0.0;
      break;
    default:
      shouldReplay = 1.0;
  }

  if (!user?.email) {
    shouldReplay = 0.0;
  }

  console.log("SHOULD REPLAY", shouldReplay);

  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_API,
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: shouldReplay,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: shouldReplay,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: shouldReplay,
    });
  } catch (error) {
    console.log("SEntry Error");
  }

  // SENTRY
}

function isSentryInitialized() {
  try {
    // Get the current Sentry Hub instance
    const hub = Sentry.getCurrentHub().getClient();
    // Check if there is a client bound to the hub
    return hub !== undefined;
  } catch (error) {
    // If there's an error accessing Sentry, assume it's not initialized
    return false;
  }
}

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, session, refreshToken } = useAuth();
  const { propertyID, accountID, onChangeAccountProperty } = useAccounts();
  const navigate = useNavigate();

  // get list of default accounts and properties
  const accountList = useQuery(["accountList", user?.id || ""], () =>
    api_get_account_list({
      userID: user?.id || "",
      query: { includeProperty: true, includeOwnership: true },
    })
  );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("login", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (isLoggedIn && !!user) {
      if (!isSentryInitialized()) {
        initReplay(user);
      }

      // userflow.identify(user?.id || "", {
      //   name: (user?.firstName || "") + "_" + (user?.lastName || ""),
      //   email: user?.email || "",
      // });

      try {
        Sentry.setUser({
          id: user?.id || "",
          email: user?.email || "",
          username: user?.firstName + "_" + user?.lastName || "",
        });
      } catch (error) {
        console.log("SENTRY ERROR:", error);
      }

      // let shouldReplay = 1.0;

      // switch (user?.email) {
      //   case "sam@agxfarm.com":
      //     shouldReplay = 0.0;
      //     break;
      //   case "cal@agxfarm.com":
      //     shouldReplay = 0.0;
      //     break;
      //   default:
      //     shouldReplay = 1.0;
      // }

      // if (!user?.email) {
      //   shouldReplay = 0.0;
      // }

      // console.log("SHOULD REPLAY", shouldReplay);

      // Sentry.init({
      //   dsn: process.env.REACT_APP_SENTRY_API,
      //   integrations: [
      //     new BrowserTracing(),
      //     new Sentry.Replay({
      //       maskAllText: false,
      //       maskAllInputs: false,
      //       blockAllMedia: false,
      //     }),
      //   ],

      //   // Set tracesSampleRate to 1.0 to capture 100%
      //   // of transactions for performance monitoring.
      //   // We recommend adjusting this value in production
      //   tracesSampleRate: shouldReplay,
      //   // This sets the sample rate to be 10%. You may want this to be 100% while
      //   // in development and sample at a lower rate in production
      //   replaysSessionSampleRate: shouldReplay,
      //   // If the entire session is not sampled, use the below sample rate to sample
      //   // sessions when an error occurs.
      //   replaysOnErrorSampleRate: shouldReplay,
      // });

      // // SENTRY
      // Sentry.setUser({
      //   id: user?.id || "",
      //   email: user?.email || "",
      //   username: user?.firstName + "_" + user?.lastName || "",
      // });

      if ((window as any).HubSpotConversations) {
        api_get_hubspot_auth({
          first_name: user?.firstName,
          sub: user?.id,
          last_name: user?.lastName,
          email: user?.email,
        }).then((response) => {
          (window as any).hsConversationsSettings = {
            loadImmediately: false,

            identificationEmail: user?.email,
            identificationToken: response?.token,
          };
          (window as any).HubSpotConversations.widget.load();
        });
      }

      (window as any).heap.identify(user?.id);
      (window as any).heap.addUserProperties({
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
      });
    }
  }, [user, isLoggedIn, (window as any).HubSpotConversations]);

  return children;
};

export default AuthGuard;
