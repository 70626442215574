// material-ui
import { FormControl, FormControlLabel, Switch } from "@mui/material";

// React-Query
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api_get_my_duty_status, api_put_on_duty_change } from "api-config/api-calls/axios_safety";
import useAccounts from "hooks/useAccounts";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";

// notification status options

// ==============================|| PROPERTY SELECTION ||============================== //

const UserDutyStatus = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const myDutyStatus = useQuery(
    ["myDutyStatus", user?.id],
    () => api_get_my_duty_status({ userID: user?.id }),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {}, [myDutyStatus?.data?.isOnDuty]);

  const onDutyChange = useMutation(
    (checked) =>
      api_put_on_duty_change({
        userID: user?.id,
        body: {
          isOnDuty: !!checked,
          propertyID,
          accountID,
        },
      }),
    {
      onSuccess: (data) => {
        queryClient?.setQueryData(["myDutyStatus", user?.id], () => data);
      },
      onMutate: (variables: any) => {
        console.log(
          "mutation with variables",
          variables,
          queryClient?.getQueryData(["myDutyStatus", user?.id])
        );
        const oldData: object = queryClient?.getQueryData(["myDutyStatus", user?.id]) || {};
        // ;
        queryClient?.setQueryData(["myDutyStatus", user?.id], (oldData: any) =>
          oldData
            ? {
                ...oldData,
                isOnDuty: variables,
              }
            : {}
        );
        return oldData;
      },
      onError: (error, variables, context) => {
        queryClient?.setQueryData(["myDutyStatus", user?.id], () => context);
      },
    }
  );

  // --- useAccounts ---
  const { accountID, propertyID } = useAccounts();
  // --- React-Query ---

  return (
    <FormControl
      sx={{
        border: "1px solid #e0e0e0",
        borderColor: !!myDutyStatus?.data?.isOnDuty ? "error.main" : "success.main",
        borderRadius: "12px",
        height: "100%",
        mr: 3,
        px: 1,
        py: 0.1,
      }}
    >
      {/* <InputLabel id="demo-simple-select-label">"</InputLabel> */}
      <FormControlLabel
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          color: "grey.800",
        }}
        control={
          <Switch
            checked={!!myDutyStatus?.data?.isOnDuty}
            onChange={(e) => onDutyChange?.mutate(e?.target?.checked)}
            color="info"
          />
        }
        label={!!myDutyStatus?.data?.isOnDuty ? "On Duty" : "Off Duty"}
      />
    </FormControl>
  );
};

export default UserDutyStatus;
