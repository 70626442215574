// project import
import pages from "./pages";
import other from "./other";
import managementSection from "./managementSection";
import livestockSection from "./livestockSection";
import livestockSectionFM from "./livestockSectionFM";

import mainSection from "./mainSection";
import userTrackingSection from "./userTrackingSection";

// types
import { NavItemType } from "types";
import safetySection from "./agSafe";
import agMeshSection from "./agMeshSection";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [
    mainSection,
    livestockSection,
    // livestockSectionFM,
    agMeshSection,
    // safetySection,

    managementSection,
  ],
};

export default menuItems;
