// third-party
import { FormattedMessage } from "react-intl";

// MUI IMPORT ICONS
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Dashboard from "@mui/icons-material/Dashboard";
import Landscape from "@mui/icons-material/Landscape";
import Map from "@mui/icons-material/Map";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import Pets from "@mui/icons-material/Pets";
import ScheduleOutlined from "@mui/icons-material/ScheduleOutlined";
import Sensors from "@mui/icons-material/Sensors";
import Warehouse from "@mui/icons-material/Warehouse";
import ArticleRounded from "@mui/icons-material/ArticleRounded";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Article from "@mui/icons-material/Article";
// ---

// assets
import {
  IconBrandChrome,
  IconClipboardList,
  IconDrone,
  IconHelp,
  IconSitemap,
  IconUserCheck,
} from "@tabler/icons";
import { IconCowBody } from "assets/special_icons";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDrone,
  Landscape,
  Dashboard,
  CalendarMonth,
  Sensors,
  Map,
  IconClipboardList,
  IconUserCheck,
  PeopleAltOutlined,
  ScheduleOutlined,
  Pets,
  Warehouse,
  ArticleRounded,
  Article,
  IconCowBody,
  CalendarToday,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const livestockSection = {
  id: "livestockSection",
  title: <FormattedMessage id="AgStock" />,
  // caption: <FormattedMessage id="Livestock" />,
  type: "group",
  children: [
    // {
    // id: "livestock-main",
    // title: <FormattedMessage id="Livestock" />,
    // type: "collapse",
    // icon: icons.Pets,
    // children: [
    //   {
    //     id: "overview",
    //     title: <FormattedMessage id="Overview" />,
    //     type: "item",
    //     url: "/livestock-overview",
    //     breadcrumbs: false,
    //   },
    // ],
    // },
    {
      id: "livestockOverview",
      title: <FormattedMessage id="Livestock" />,
      type: "item",
      url: "/livestock",
      icon: icons.IconCowBody,
      breadcrumbs: false,
    },
    {
      id: "inventoryOverview",
      title: <FormattedMessage id="Inventory" />,
      type: "item",
      url: "/inventory",
      icon: icons.Warehouse,
      breadcrumbs: false,
    },
    {
      id: "reports",
      title: <FormattedMessage id="Reports" />,
      type: "item",
      url: "/reports",
      icon: icons.ArticleRounded,
      breadcrumbs: false,
    },
    // {
    //   id: "reportsOverview",
    //   title: <FormattedMessage id="Reports (coming soon)" />,
    //   type: "item",
    //   url: "/reports",
    //   icon: icons.ArticleRounded,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "calendar",
    //   title: <FormattedMessage id="Calendar" />,
    //   type: "item",
    //   url: "/calendar",
    //   icon: icons.CalendarToday,
    //   breadcrumbs: false,
    // },
  ],
};

export default livestockSection;
