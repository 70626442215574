// project imports
import useAccounts from "hooks/useAccounts";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import useAuth from "hooks/useAuth";

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const RQProvider = ({ children }) => {
  const { logout } = useAuth();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: async (error, query) => {
        // How to get status code fo error
        // console.log('we got top level error', error);
        // if (error.request.status === 403) {
        //   try {
        //     await logout();
        //   } catch (err) {
        //     console.error(err);
        //   }
        // }
      },
    }),
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000, // 5 min default stale time
      },
    },
  });


  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
};


export default RQProvider;
