// icon:baby-bottle | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconBabyBottle(props) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M11.28 2.8l-.5.5a5.5 5.5 0 00-4.37-.43l-.08-.07A2.5 2.5 0 002.8 6.33l.07.08a5.5 5.5 0 00.43 4.37l-.5.5a1.5 1.5 0 000 2.12l1.41 1.42a1.5 1.5 0 002.12 0l.35-.36 7.08 7.07a1.5 1.5 0 002.12 0l5.65-5.65a1.5 1.5 0 000-2.12l-7.07-7.08.36-.35a1.5 1.5 0 000-2.12L13.4 2.8a1.5 1.5 0 00-2.12 0m2.48 2.47l-8.49 8.49-1.41-1.42 8.48-8.48m2.48 7.77l-3.19 3.19-1.06-1.06 3.19-3.19m3.18 3.19l-3.18 3.18-1.07-1.06 3.19-3.19z" />
    </svg>
  );
}

export default IconBabyBottle;
