import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  List,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import React, { useState, useEffect } from "react";

const PhoneCheck = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log("USER AGENT ", userAgent);

    const isMobile = /iPhone|iPod/i.test(userAgent);

    if (isMobile) {
      setIsVisible(true);
    }
  }, []);

  //   if (!isVisible) return null;

  const handleDownload = () => {
    window.location.href = "https://apps.apple.com/us/app/agx/id1667410671"; // Redirects the user to the app store link
  };

  return (
    <>
      <Modal
        open={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
      >
        <ModalDialog>
          {/* <ModalClose /> */}
          <DialogTitle>We have an app!</DialogTitle>
          <DialogContent>It's much easier to navigate the app when on a phone</DialogContent>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button variant="solid" color="primary" onClick={handleDownload}>
              Download app
            </Button>
            <Button variant="outlined" color="neutral" onClick={() => setIsVisible(false)}>
              Use the website
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
      {children}
    </>
  );
};

export default PhoneCheck;
