import React, { createContext, useEffect, useReducer, ReactElement } from "react";

// third-party
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

// axios
import axios from "axios";

// reducer - state management
import { LOGIN, LOGOUT, REFRESH, UPDATE } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "components/old/Loader";
import { AWS_API } from "config";
import { AWSCognitoContextType, InitialLoginContextProps } from "types/auth";
import { StringColorProps } from "types";
import useAccounts from "hooks/useAccounts";
import { UserProfile } from "types/user-profile";

// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

export const userPool = new CognitoUserPool({
  UserPoolId: AWS_API.poolId,
  ClientId: AWS_API.appClientId,
});

// Function to manually decode JWT
function decodeToken(token) {
  try {
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Convert base64url to base64
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding token:", error);
    return null; // Return null if the token cannot be decoded
  }
}

const setSession = (
  serviceToken?: string | null,
  userData?: object | null,
  sub?: string | null
) => {
  // ;
  if (serviceToken) {
    // ;
    window.localStorage.setItem("serviceToken", serviceToken);
    if (sub && userData) {
      window.localStorage.setItem("sub", sub);
      window.localStorage.setItem("userData", JSON.stringify(userData));
    }
  } else {
    // ;
    window.localStorage.removeItem("serviceToken");
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("sub");
  }
};
// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { logoutAccountData, loadAccountList }: any = useAccounts();

  useEffect(() => {
    const init = async () => {
      try {
        // const serviceToken = window.localStorage.getItem("serviceToken");
        // const serviceToken = localStorage.getItem("serviceToken");

        const userData = JSON.parse(window.localStorage.getItem("userData") || "null");
        const sub = window.localStorage.getItem("sub");

        const serviceToken = (await refreshToken()) as String;
        const decoded = decodeToken(serviceToken as string);
        const currentTime = Math.floor(Date.now() / 1000);

        const isExpired = (decoded?.exp ?? 0) < currentTime;
        if (serviceToken && sub && !isExpired) {
          // ;
          setSession(serviceToken as string, userData, sub);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              session: serviceToken as string,
              user: userData,
            },
          });
        } else {
          // ;
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const getUsr = (email: string) => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool: userPool,
    });
  };

  // const setUsrData = async () => {
  //   return new Promise((resolve, reject) => {
  //     var usr = userPool.getCurrentUser();
  //     new Promise((res) => usr!.getSession(res));
  //     usr!.getUserAttributes(async (err, result) => {
  //       if (err) {
  //         alert(err.message || JSON.stringify(err));
  //         reject("error");
  //       }
  //       const subVal = result?.filter((el) => el["Name"] === "sub");
  //       const givenName = result?.filter((el) => el["Name"] === "given_name");
  //       const familyName = result?.filter((el) => el["Name"] === "family_name");
  //       const email = result?.filter((el) => el["Name"] === "email");
  //       ;
  //       if (subVal!.length > 0) {
  //         // ;
  //         // await loadAccountList({ userID: subVal![0]["Value"] });
  //         localStorage.setItem("sub", subVal![0]["Value"]);
  //       }
  //       if (givenName!.length > 0 && familyName!.length > 0) {
  //         localStorage.setItem("given_name", givenName![0]["Value"]);
  //         localStorage.setItem("family_name", familyName![0]["Value"]);
  //       }
  //       if (email!.length > 0) {
  //         localStorage.setItem("email", email![0]["Value"]);
  //       }
  //       resolve("success");
  //     });
  //   });
  // };

  const login = async (email: string, password: string) =>
    new Promise((success, rej) => {
      localStorage.clear();
      const usr = getUsr(email);
      const authData = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      usr.authenticateUser(authData, {
        onSuccess: async (session: CognitoUserSession) => {
          // ;
          const sessionData = session.getIdToken().decodePayload();
          const sessionToken = session.getAccessToken().getJwtToken();

          const userData = {
            email: sessionData?.email || "",
            phone: sessionData?.phone_number || "",
            firstName: sessionData?.given_name || "",
            lastName: sessionData?.family_name || "",
            id: sessionData?.sub || "",
            image: sessionData?.profile_photo || "",
          };

          setSession(sessionToken, userData, sessionData.sub);
          // await setUsrData();
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: userData,
              session: sessionToken,
            },
          });
          success("");
          // window.location.reload();
        },
        onFailure: (err) => {
          rej(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.error("onFailure", userAttributes);
          // // User was signed up by an admin and must provide new
          // // password and required attributes, if any, to complete
          // // authentication.
          // // the api doesn't accept this field back
          // delete userAttributes.email_verified;
          // // unsure about this field, but I don't send this back
          // delete userAttributes.phone_number_verified;
          // // Get these details and call
          // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
        },
      });
    });

  const updateUser = (userData: UserProfile) => {
    const oldUserData = JSON.parse(window.localStorage.getItem("userData") || "");
    const newUserData = { ...oldUserData, ...userData };
    window.localStorage.setItem("userData", JSON.stringify(newUserData));
    dispatch({
      type: UPDATE,
      payload: {
        isLoggedIn: true,
        user: userData,
      },
    });
  };

  const register = (
    email: string,
    password: string,
    phoneNumber: string,
    firstName: string,
    lastName: string
  ) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "phone_number",
            Value: phoneNumber,
          }),
          new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
          new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
          //   new CognitoUserAttribute({
          //     Name: "name",
          //     Value: `${firstName} ${lastName}`,
          //   }),
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const registerVerify = (email: string, password: string, code: string) =>
    new Promise((success, rej) => {
      const usr = getUsr(email);
      usr.confirmRegistration(code, true, async function (_err, data) {
        if (_err) {
          // alert(_err.message || JSON.stringify(_err));
          rej(_err);
          return;
        } else {
          try {
            await login(email, password);
          } catch (err) {
            console.error(err);
          }
        }
      });
    });

  const registerPhoneVerify = (email: string, code: string) =>
    new Promise((success, rej) => {
      const usr = getUsr(email);
      usr.confirmRegistration(code, true, function (_err, data) {
        if (_err) {
          // alert(_err.message || JSON.stringify(_err));
          rej(_err);
          return;
        } else {
          success(data);
        }
      });
    });

  const registerResendCode = (email: string) =>
    new Promise((success, rej) => {
      const usr = getUsr(email);
      usr.resendConfirmationCode(function (_err, data) {
        if (_err) {
          // alert(_err.message || JSON.stringify(_err));
          rej(_err);
          return;
        } else {
          success(data);
        }
      });
    });

  const logout = () => {
    const loggedInUser = userPool.getCurrentUser();
    // Clear session and local storage regardless of loggedInUser status
    setSession(null, null, null);
    localStorage.clear();
    logoutAccountData();
    dispatch({ type: LOGOUT });

    if (loggedInUser) {
      loggedInUser.signOut();
    } else {
    }
  };

  const refreshToken = () => {
    return new Promise((success, rej) => {
      const usr = userPool.getCurrentUser();
      if (usr) {
        usr!.getSession((err, session) => {
          if (err) {
            console.log(err);
            rej(err);
            return;
          }
          usr!.refreshSession(session.getRefreshToken(), (err, refreshedSession) => {
            if (err) {
              console.log(err);
              rej(err);
              return;
            }
            const serviceToken = refreshedSession.getAccessToken().getJwtToken();
            setSession(serviceToken, null, null);
            dispatch({
              type: REFRESH,
              payload: {
                isLoggedIn: true,
                session: serviceToken,
              },
            });
            setTimeout(refreshToken, refreshedSession.accessToken?.payload.exp * 1000 - Date.now());
            success(serviceToken);
          });
        });
      } else {
        logout();
        rej("null");
      }
    });
  };

  const resetPassword = (email: string) =>
    new Promise((success, rej) => {
      const usr = getUsr(email);
      usr.forgotPassword({
        onSuccess: (data) => {
          success(data);
        },
        onFailure: (_err) => {
          console.error("onError: ", _err);
          rej(_err);
        },
        inputVerificationCode: (data) => {
          success(data);
        },
      });
    });

  const resetPasswordVerify = (email: string, code: string, password: string) =>
    new Promise((success, rej) => {
      const usr = getUsr(email);
      usr.confirmPassword(code, password, {
        onSuccess: (data) => {
          success(data);
        },
        onFailure: (_err) => {
          console.error("onFailure", _err);
          rej(_err);
        },
      });
    });

  const updateAttribute = (farmID, action) => {
    var usr = userPool.getCurrentUser();
    new Promise((res) => usr!.getSession(res));

    if (action === "add") {
      if (
        JSON.parse(localStorage.getItem("custom:farmIDs")!) == null
        // JSON.parse(localStorage.getItem("custom:farmIDs")!)[0] == null
      ) {
        var farmIDList: any[] = [];
      } else {
        var farmIDList: any[] = JSON.parse(localStorage.getItem("custom:farmIDs")!);
      }
      // var farmIDList: any[] = [];
      farmIDList.push(farmID);
    } else if (action === "delete") {
      var farmIDList: any[] = JSON.parse(localStorage.getItem("custom:farmID2")!);
      farmIDList = farmIDList.filter((item) => item !== farmID);
    } else {
      farmIDList = [];
    }

    const customAttribute = new CognitoUserAttribute({
      Name: "custom:farmIDs",
      Value: JSON.stringify(farmIDList),
    });

    usr!.updateAttributes([customAttribute], function (err, result) {
      console.log({ err, result });
      localStorage.setItem("custom:farmIDs", JSON.stringify(farmIDList));
      window.location.reload();
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        resetPasswordVerify,
        registerVerify,
        registerResendCode,
        updateAttribute,
        refreshToken,
        updateUser,
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
