import React, { ReactNode, Ref } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardContent, CardHeader, Divider, Paper } from "@mui/material";

import { Typography } from "@mui/joy";
interface SubCardProps {
  children: ReactNode | string | null;
  content?: boolean;
  className?: string;
  contentClass?: string;
  darkTitle?: boolean;
  secondary?: any;
  sx?: {};
  contentSX?: {};
  title?: any;
  error?: boolean;
  subheader?: string;
  enableOverflow?: boolean;
}

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = React.forwardRef(
  (
    {
      children,
      className,
      content,
      contentClass,
      darkTitle,
      secondary,
      sx = {},
      contentSX = {},
      title,
      error = false,
      subheader,
      enableOverflow = false,
      ...others
    }: SubCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        sx={{
          border: "1px solid",
          borderColor: error
            ? theme.palette.error.main
            : theme.palette.mode === "dark"
            ? theme.palette.dark.light + 15
            : theme.palette.primary.light,
          ":hover": {
            boxShadow:
              theme.palette.mode === "dark"
                ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                : "0 2px 14px 0 rgb(32 40 45 / 8%)",
          },
          ...sx,
        }}
        {...others}
      >
        <Box
          sx={{
            // enable vertical overflow if enableOverflow set to true
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            overflowY: enableOverflow ? "scroll" : "hidden",
            // hide scrollbar
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {/* card header and action */}
          {!darkTitle && title && (
            <CardHeader
              sx={{ p: 1.5 }}
              title={<Typography level="h3">{title}</Typography>}
              action={secondary}
              subheader={subheader}
            />
          )}
          {darkTitle && title && (
            <CardHeader
              sx={{ p: 1.5 }}
              title={<Typography level="h3">{title}</Typography>}
              action={secondary}
              subheader={subheader}
            />
          )}

          {/* content & header divider */}
          {title && (
            <Divider
              sx={{
                opacity: 1,
                borderColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.light + 15
                    : theme.palette.primary.light,
              }}
            />
          )}

          {/* card content */}
          {content && (
            <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ""}>
              {children}
            </CardContent>
          )}
          {!content && children}
        </Box>
      </Card>
    );
  }
);

SubCard.defaultProps = {
  content: true,
};

export default SubCard;
