import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Mutation, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Stack,
  ButtonGroup,
  Tooltip,
  CircularProgress,
} from "@mui/joy";
import { InfoOutlined, Launch, More } from "@mui/icons-material";
import { useSnackbar } from "notistack";

import {
  api_get_account_data,
  api_get_account_referal_code,
  api_get_referal_log,
  api_post_referal_invite,
} from "api-config/api-calls/axios_account";
import useAccounts from "hooks/useAccounts";
import { width } from "@mui/system";
import useAuth from "hooks/useAuth";
import ReferalEmailFormStack from "./ReferalEmailFormStack";

const ReferModal = (props) => {
  const { open, setOpen } = props;
  const { accountID } = useAccounts();
  const { user } = useAuth();

  const [referalCount, setReferalCount] = React.useState(0);
  const [tooltipText, setTooltipText] = React.useState("Click to copy");
  const [sendingInProgress, setSendingInProgress] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(accountData?.data?.shareCode || "")
      .then(() => {
        setTooltipText("Copied!");
        setTimeout(() => setTooltipText("Click to copy"), 2000); // Reset tooltip text after 2 seconds
      })
      .catch(() => {
        setTooltipText("Failed to copy");
      });
  };

  const referalLog = useQuery(["referalLog", accountID], () => api_get_referal_log({ accountID }), {
    enabled: !!accountID,
  });

  const accountData = useQuery(
    ["accountData", accountID],
    () => api_get_account_data({ accountID }),
    {
      enabled: !!accountID,
    }
  );

  const accountReferalCount = useQuery(
    ["referalCount", accountID],
    () => api_get_account_referal_code({ accountID }),
    {
      enabled: !!accountID,
    }
  );

  React.useEffect(() => {
    if (accountReferalCount?.data) {
      setReferalCount(accountReferalCount.data.length);
    } else {
      console.log("HERE IS PROBLEM");
    }
  }, [accountReferalCount.isLoading]);

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Box
            sx={{
              maxHeight: "60vh",
              minWidth: "50vw",
              borderRadius: 10,
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* <Button
              sx={{
                position: "absolute", // Positioning the button absolutely
                top: 16, // Right distance from the top of the container
                right: 8, // Right distance from the right of the container
                zIndex: 1, // Ensuring it's above other content
              }}
              variant="outlined"
              endDecorator={<Launch />}
              onClick={() => console.log("TODO LATER")}
            >
              I want more details
            </Button> */}

            {accountData.data?.isPaid || accountData.data?.isAllReferals ? (
              <>
                <Typography level="h1">Thank you for refering other farmers</Typography>
                <Typography level="body-lg">
                  We very much appreciate your help in spreading the word about AgX.
                </Typography>
              </>
            ) : (
              <>
                <Typography level="h1">
                  You have referred {referalCount} {referalCount === 1 ? "person" : "people"}.
                </Typography>
                <Typography level="body-lg">
                  Refer {3 - referalCount} more people to get AgStock free, forever.
                </Typography>
              </>
            )}

            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Box sx={{ mt: 4, mb: 5 }}>
                <Box
                  sx={{
                    borderRadius: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Tooltip title={tooltipText} arrow>
                    <Box
                      sx={{
                        borderRadius: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer", // Indicate that the text is clickable
                      }}
                      onClick={handleCopy}
                    >
                      <Typography textColor="grey" level="h1">
                        {accountData?.data?.shareCode}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  sx={{ maxHeight: "60vh", width: "100%", overflowY: "auto", overflowX: "hidden" }}
                >
                  <Stack justifyContent="center">
                    <ReferalEmailFormStack referalLog={referalLog} accountData={accountData} />
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            {referalLog?.data?.length > 0 && (
              <Button variant="solid" color="primary" onClick={() => setOpen(false)}>
                I'll let them know to check email/SMS
              </Button>
            )}
            <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
              Maybe later
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default ReferModal;
