import { lazy } from "react";

// project imports
import Loadable from "components/old/Loadable";
import MainLayout from "layout/MainLayout";
import AuthGuard from "utils/route-guard/AuthGuard";
import OnboardCheck from "on-load/OnboardCheck";
import PropertyAccountGuard from "modules/onboarding/PropertyAccountGuard";
import PhoneCheck from "on-load/PhoneCheck";
import SubscriptionHandler from "on-load/SubscriptionHandler";
// on-load but authenticated

// sample page routing
const UserReg = Loadable(lazy(() => import("views/authentication")));
const Aerial = Loadable(lazy(() => import("views/pages/aerial")));

const Devices = Loadable(lazy(() => import("views/pages/devices")));
const Store = Loadable(lazy(() => import("views/pages/store")));

const Profile = Loadable(lazy(() => import("views/pages/profile")));
const LivestockOverviewPage = Loadable(
  lazy(() => import("views/pages/livestock/LivestockOverviewPage"))
);

const LivestockCommandFM = Loadable(lazy(() => import("modules/agStockFM/LivestockCommandFM")));
const UserManagement = Loadable(lazy(() => import("views/pages/user-management")));
// const Checkin = Loadable(lazy(() => import("views/pages/check-in")));
const Dashboard = Loadable(lazy(() => import("views/pages/dashboard")));
const InventoryPage = Loadable(lazy(() => import("views/pages/inventory")));
const ReportsPage = Loadable(lazy(() => import("views/pages/reports")));
const CalendarPage = Loadable(lazy(() => import("views/pages/calendar")));
const SettingsPage = Loadable(lazy(() => import("views/pages/settings")));
const DocumentsPage = Loadable(lazy(() => import("views/pages/documents")));
const NewAccountOnboarding = Loadable(
  lazy(() => import("modules/onboarding/NewAccountOnboarding"))
);

const NewDeviceCheck = Loadable(lazy(() => import("on-load/NewDeviceCheck")));
// AgSafe
const InductionPage = Loadable(lazy(() => import("views/pages/agSafe/Induction")));
const PoliciesPage = Loadable(lazy(() => import("views/pages/agSafe/Policies")));
const ChecklistPage = Loadable(lazy(() => import("views/pages/agSafe/Checklist")));
const SafetyReportsPage = Loadable(lazy(() => import("views/pages/agSafe/SafetyReports")));

// ==============================|| MAIN ROUTING ||============================== //

// useEffect(() => {
//   if (isLoggedIn && (propertyID === null || accountID === null)) {
//     if (accountList.data?.[0]) {
//       onChangeAccountProperty({
//         accountID: accountList.data?.[0]?.ID,
//         propertyID: accountList.data?.[0]?.properties?.[0]?.ID,
//       });
//     }
//   }
// }, [accountList.isLoading]);

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      {/* <LoadLocalData> */}
      <NewAccountOnboarding>
        <PropertyAccountGuard>
          <PhoneCheck>
            <SubscriptionHandler>
              <NewDeviceCheck>
                <OnboardCheck>
                  <MainLayout />
                </OnboardCheck>
              </NewDeviceCheck>
            </SubscriptionHandler>
          </PhoneCheck>
        </PropertyAccountGuard>
      </NewAccountOnboarding>
      {/* </LoadLocalData> */}
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/user-reg",
      element: <UserReg />,
    },

    // {
    //   path: "/properties",
    //   element: <PropertiesPage />,
    // },
    {
      path: "/aerial",
      element: <Aerial />,
    },
    {
      path: "/devices",
      element: <Devices />,
    },
    {
      path: "/store",
      element: <Store />,
    },
    {
      path: "/user-management",
      element: <UserManagement />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/livestock",
      element: <LivestockOverviewPage />,
    },
    // {
    //   path: "/livestockFMTable1",
    //   element: <LivestockOverviewPageFM />,
    // },
    {
      path: "/livestockFMTable2",
      element: <LivestockCommandFM />,
    },
    {
      path: "/inventory",
      element: <InventoryPage />,
    },
    {
      path: "/reports",
      element: <ReportsPage />,
    },

    {
      path: "/calendar",
      element: <CalendarPage />,
    },
    {
      path: "/settings",
      element: <SettingsPage />,
    },
    {
      path: "/documents",
      element: <DocumentsPage />,
    },
    {
      path: "/checklists",
      element: <ChecklistPage />,
    },
    {
      path: "/policies",
      element: <PoliciesPage />,
    },
    {
      path: "/induction",
      element: <InductionPage />,
    },
    {
      path: "/safety-reports",
      element: <SafetyReportsPage />,
    },
  ],
};

export default MainRoutes;
