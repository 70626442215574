// ----------------- //
//  LIVESTOCK API
// ----------------- //
// livestock API requests

// --- IMPORTS
// axios
import axios from "axios";
// react-query
import { useQuery } from "@tanstack/react-query";
import {
  BASE_API_URL,
  API_CONFIG_GET,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  API_CONFIG_PATCH,
  API_CONFIG_DEL,
} from "api-config/api-helper";
import { applyAuthTokenInterceptor } from "api-config/api-helper/axios_interceptor";

// --- CONFIG
const LCL_URL = "property";
const axiosInstance = axios.create({
  baseURL: BASE_API_URL + LCL_URL,
});

applyAuthTokenInterceptor(axiosInstance);

// --- REQUESTS
// Get Property
const api_get_property_data = ({ propertyID }) => {
  // ;
  const FNC_URL = "/full/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data[0]); // return first property (only 1)
};
// Add Property
const api_post_property = ({ body }) => {
  const FNC_URL = "";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// properyt settings
const api_patch_dashboard_config = ({ propertyID, body }) => {
  const FNC_URL = "/settings/dashboard/config/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_PATCH,
      url: FNC_URL,
      // query parameters
      params: {},
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

const api_patch_property_full = (formData) => {
  const { propertyID, body } = formData;
  const FNC_URL = "/full/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_PATCH,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: body,
    })
    .then((res) => res.data);
};

const api_patch_property_features = (props) => {
  const { body, propertyID } = props;
  const FNC_URL = "/features/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_PATCH,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// Delete Property
const api_del_property = ({ propertyID }) => {
  const FNC_URL = propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_DEL,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// Update Property
const api_put_property = ({ propertyID, body }) => {
  const FNC_URL = propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// Stocking Rate
const api_get_property_hist_stocking_rate = ({ propertyID, query }) => {
  const FNC_URL = "/history/stocking_rate/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// Stocking Rate
const api_get_property_hist_stocking_rate2 = ({ propertyID, query }) => {
  const FNC_URL = "/history/stocking_rate_2/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// reportData, propertyID, reportType
const api_get_property_event_report = ({ propertyID, reportType }) => {
  const FNC_URL = "/report/" + propertyID + "/event/" + reportType;
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // query parameters
      params: {},
      data: {},
    })
    .then((res) => res.data);
};

// reportData, propertyID, reportType
const api_get_property_paddock_report = ({ propertyID, reportType }) => {
  const FNC_URL = "/report/" + propertyID + "/paddock/" + reportType;
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // query parameters
      params: {},
      data: {},
    })
    .then((res) => res.data);
};

// reportData, propertyID, reportType
const api_get_property_mob_timemachine = ({ propertyID, date }) => {
  const FNC_URL = "/timemachine/mobs/" + propertyID + "/" + date;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// paddockMap, propertyID, reportType
const api_get_property_paddock_map = ({ propertyID }) => {
  const FNC_URL = "/paddockMap/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

const api_get_property_days_since_grazing = ({ propertyID, query }) => {
  const FNC_URL = "/days_since_grazing/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

const api_get_property_curr_stocking_rate = ({ propertyID, query }) => {
  const FNC_URL = "/stocking_rate/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: propertyHistoryFull
const api_get_property_history_full = ({ propertyID, query }) => {
  const FNC_URL = "/history/full/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: "inventoryTypes", "livestock"
const api_get_property_inventory_types = ({ propertyID, query }) => {
  const FNC_URL = "/inventory/types/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: "inventoryUnits", "livestock",
const api_get_property_inventory_units = ({ propertyID, query }) => {
  const FNC_URL = "/inventory/units/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: "inventoryCombined", "livestock",
const api_get_property_inventory_combined = ({ propertyID, query }) => {
  const FNC_URL = "/inventory/livestock/combined/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: updateGate
const api_put_property_update_gate = (formData) => {
  const { propertyID, body } = formData;
  const FNC_URL = "/update_gate/" + propertyID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      // query parameters
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

export {
  api_get_property_data,
  api_post_property,
  api_del_property,
  api_put_property,
  api_patch_property_full,
  api_get_property_hist_stocking_rate,
  api_get_property_hist_stocking_rate2,
  api_get_property_days_since_grazing,
  api_get_property_curr_stocking_rate,
  api_patch_property_features,
  api_get_property_history_full,
  api_get_property_inventory_types,
  api_get_property_inventory_units,
  api_get_property_inventory_combined,
  api_put_property_update_gate,
  api_get_property_event_report,
  api_get_property_paddock_report,
  api_get_property_mob_timemachine,
  api_get_property_paddock_map,
  api_patch_dashboard_config,
};
