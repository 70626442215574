import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "components/old/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/auth-pages/Login"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/auth-pages/Register"))
);
const AuthRegisterVerify = Loadable(
  lazy(() => import("views/authentication/auth-pages/Register"))
);
const AuthRegisterInfo = Loadable(
  lazy(() => import("views/authentication/auth-pages/Register"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/authentication/auth-pages/ForgotPassword"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister stage={"register"} />,
    },
    {
      path: "/register/verify",
      element: <AuthRegisterVerify stage={"verify"} />,
    },
    {
      path: "/register/info",
      element: <AuthRegisterInfo stage={"info"} />,
    },
    {
      path: "/forgot-password",
      element: <AuthForgotPassword />,
    },
    // {
    //     path: '/forgot',
    //     element: <AuthForgotPassword />
    // }
  ],
};

export default LoginRoutes;
