// ----------------- //
//  LIVESTOCK API
// ----------------- //
// livestock API requests

// --- IMPORTS
// axios
import axios from "axios";
// react-query
import { useQuery } from "@tanstack/react-query";
import {
  BASE_API_URL,
  API_CONFIG_GET,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  API_CONFIG_PATCH,
  API_CONFIG_DEL,
} from "api-config/api-helper";
import { applyAuthTokenInterceptor } from "api-config/api-helper/axios_interceptor";

// --- CONFIG
const LCL_URL = "safety";
const axiosInstance = axios.create({
  baseURL: BASE_API_URL + LCL_URL,
});

applyAuthTokenInterceptor(axiosInstance);

// --- REQUESTS

// ON DUTY
// KEY: accountDutyStatus
const api_get_account_duty_status = ({ accountID }) => {
  const FNC_URL = "/get_account_duty_status/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: myDutyStatus
const api_get_my_duty_status = ({ userID }) => {
  const FNC_URL = "/get_my_duty_status/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: onDutyOverride
const api_put_on_duty_override = ({ body }) => {
  const FNC_URL = "/on_duty_override";
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

// KEY: onDutyChange
const api_put_on_duty_change = ({ userID, body }) => {
  const FNC_URL = "/on_duty/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

// KEY: dutyReminder
const api_put_on_duty_reminder = ({ userID }) => {
  const FNC_URL = "/check_out_reminder/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: documentAssignmentStatus
const api_get_document_assignment_status = ({ documentID }) => {
  const FNC_URL = "/document_assignment_status/" + documentID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: documentsPending
const api_get_documents_pending = ({ userID }) => {
  const FNC_URL = "/documents_pending/" + userID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: assignDocument
const api_put_assign_document = ({ body }) => {
  const FNC_URL = "/assign_document/";
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

// KEY: assignDocument
const api_put_user_document_response = ({ body }) => {
  const FNC_URL = "/user_document_response/";
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

// CHECKLISTS

// KEY: accountChecklists
const api_get_account_checklists = ({ accountID }) => {
  const FNC_URL = "/checklists/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: addChecklist
const api_post_add_checklist = ({ body }) => {
  const FNC_URL = "/add_checklist";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

// KEY: addChecklist
const api_put_edit_checklist = ({ checklistID, body }) => {
  const FNC_URL = "/edit_checklist/" + checklistID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      data: body,
    })
    .then((res) => res.data);
};

export {
  // api_post_document,
  // api_get_account_documents,
  api_get_account_duty_status,
  api_get_my_duty_status,
  api_put_on_duty_override,
  api_put_on_duty_change,
  api_get_document_assignment_status,
  api_get_documents_pending,
  api_put_assign_document,
  api_put_on_duty_reminder,
  api_put_user_document_response,
  api_get_account_checklists,
  api_post_add_checklist,
  api_put_edit_checklist,
};
