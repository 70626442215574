import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/joy/Button";

import Typography from "@mui/joy/Typography";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { ROOT_PATH } from "config";
import AnimateButton from "components/old/extended/AnimateButton";
import useAuth from "hooks/useAuth";
import * as Sentry from "@sentry/react";

const customFontStyle = {
  fontFamily: '"Courier New", Courier, monospace',
};

const ErrorPage = ({ error, resetErrorBoundary } = {}) => {
  const { logout, user } = useAuth();

  Sentry.captureException(error);

  console.log("ERROR ERROR ERROR", error, "--", error?.message);

  if (error?.message.includes("403") || error?.message.includes("unauthorized")) {
    // If the error message indicates a 403 Unauthorized error, navigate to login
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100vh", ...customFontStyle }}
    >
      <Typography level="h1"> Something went wrong, we are very sorry.</Typography>

      <Typography level="body-lg" style={{ padding: 10 }}>
        Your issue has been logged, we are working on it!
      </Typography>
      <Box display="flex" flexDirection="row" gap={2} padding={2}>
        <Button
          component={Link}
          to={ROOT_PATH}
          onClick={resetErrorBoundary}
          startIcon={<HomeIcon />}
          size="lg"
        >
          Go Home
        </Button>
        <Button
          component="a"
          href="#" // Keep this to maintain the link appearance and default behavior
          onClick={logout} // Attach the logout function to onClick event
          size="lg"
          variant="outlined"
        >
          Logout and reload
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
