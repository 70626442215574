import { Typography } from "@mui/material";
import { FEET_TO_METERS, METERS_TO_FEET } from "utils/constants/general";
import { v4 as uuid } from "uuid";
// import all of turf
import * as turf from "@turf/turf";
import geojson2svg, { Renderer } from "geojson-to-svg";
import { svgToDataURL } from "modules/agStock/livestock/tools/svgToURL";

const getUniqueID = () => {
  // uuid id
  return uuid();
};

const capitalizeFL = (string) => {
  // check it's valid first
  if (string == null) return "";
  var result = string
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
  return result;
};

const firstLetter = (string) => {
  return string.toString().charAt(0).toUpperCase();
};

const getFullName = (stringFirst, stringLast) => {
  return (
    (!!stringFirst ? capitalizeFL(stringFirst.toLowerCase()) : "null") +
    " " +
    (!!stringLast ? capitalizeFL(stringLast.toLowerCase()) : "null")
  );
};

export const getFullNameFromQuery = (queryData, userID) => {
  const userData = queryData?.data?.find((user) => user.userID === userID);
  return queryData?.isLoading
    ? "Loading"
    : queryData?.isError || !userData
    ? "Error Loading Name"
    : getFullName(userData?.given_name, userData?.family_name);
};

const alphabeticalSort = (data) => {
  return data?.sort((a, b) => {
    if (a.properties.featureName < b.properties.featureName) {
      return -1;
    }
    if (a.properties.featureName > b.properties.featureName) {
      return 1;
    }
    return 0;
  });
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const getAvatarProps = (stringFirst = "", stringLast = "", bgColor = true) => {
  const name = getFullName(stringFirst, stringLast);
  return {
    sx: {
      bgcolor: bgColor ? stringToColor(name) : null,
    },
    color: bgColor ? "white" : null,
    children: (
      <Typography variant="button">
        {name.split(" ")[0][0]}
        {name.split(" ")[1][0]}
      </Typography>
    ),
  };
};

const getAge = (dateString) => {
  if (dateString != null) {
    return Math.abs(new Date(Date.now() - new Date(dateString).getTime()).getUTCFullYear() - 1970);
  } else return 0;
};

// get the age in months of an animal returning in integer format
const getMonths = (dateString) => {
  if (dateString != null) {
    const date = new Date(dateString);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const months = Math.floor(diff / (1000 * 3600 * 24 * 30));
    return months;
  } else return 0;
};

const getWeeks = (dateString) => {
  if (dateString != null) {
    const date = new Date(dateString);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const weeks = Math.floor(diff / (1000 * 3600 * 24 * 7));
    return weeks;
  } else return 0;
};

const getGreeting = () => {
  const hours = new Date().getHours();
  if (hours < 12 && hours > 4) {
    return "Good Morning";
  } else if (hours < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

const getFormattedDate = ({ day = 0, month = 0, year = 0 }) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDay() - day);
  currentDate.setMonth(currentDate.getMonth() - month);
  currentDate.setFullYear(currentDate.getFullYear() - year);
  const yearEl = currentDate.getFullYear().toString();
  const monthEl = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  const dayEl = ("0" + currentDate.getDate()).slice(-2);
  const formattedDate = yearEl + "-" + monthEl + "-" + dayEl;
  return formattedDate;
};

const dateToyyyymmdd = (date) => {
  const yearEl = date.getFullYear().toString();
  const monthEl = ("0" + (date.getMonth() + 1)).slice(-2);
  const dayEl = ("0" + date.getDate()).slice(-2);
  const formattedDate = yearEl + "-" + monthEl + "-" + dayEl;
  return formattedDate;
};

const getDaysUntil = (dateString = new Date()) => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
};

const mergeLinkedArrays = (arrays: any[][]): any[][] => {
  const idMap: Record<any, number> = {}; // Object to track IDs and their corresponding index
  const mergedArrays: any[][] = [];

  for (const array of arrays) {
    let mergedIndex = -1; // Index of the merged array that this array should be merged into

    for (const id of array) {
      if (idMap.hasOwnProperty(id)) {
        // ID is already present, merge with the existing merged array
        mergedIndex = idMap[id];
        break;
      }
    }

    if (mergedIndex !== -1) {
      // Merge with the existing merged array
      mergedArrays[mergedIndex] = mergedArrays[mergedIndex].concat(array);
    } else {
      // Create a new merged array
      mergedIndex = mergedArrays.length;
      mergedArrays.push([...array]);
    }

    // Update the idMap with the new IDs and their merged array index
    for (const id of array) {
      idMap[id] = mergedIndex;
    }
  }

  return mergedArrays;
};

export const convert_meters_to_feet_inches = (meters) => {
  const feetVar = meters * FEET_TO_METERS;
  const feet = Math.floor(feetVar);
  const inches = Math.round((feetVar - feet) * 12);
  return { feet, inches };
};

export const convert_feet_inches_to_meters = (feet, inches) => {
  const totalInches = feet * 12 + inches;
  const meters = totalInches * 0.0254;
  return meters;
};

function toSentenceCase(str) {
  // Replace underscores with spaces and split the string into words
  const words = str.replace(/_/g, " ").split(" ");

  // Capitalize the first letter of the first word
  const capitalizedWords = words.map((word, index) => {
    // Only capitalize the first letter of the first word
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.toLowerCase();
  });

  // Join the words back into a string
  return capitalizedWords.join(" ");
}

const DownloadJSON = (data, name) => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = name + ".json";
  link.click();
};

export {
  getUniqueID,
  capitalizeFL,
  firstLetter,
  getAge,
  getMonths,
  getFullName,
  getGreeting,
  getFormattedDate,
  getDaysUntil,
  getWeeks,
  getAvatarProps,
  alphabeticalSort,
  mergeLinkedArrays,
  toSentenceCase,
  DownloadJSON,
  dateToyyyymmdd,
};
