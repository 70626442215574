// axios_interceptors.js
import useAuth from "hooks/useAuth"; // Make sure this is usable here as intended

function decodeToken(token) {
  try {
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Convert base64url to base64
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding token:", error);
    return null; // Return null if the token cannot be decoded
  }
}

export const applyAuthTokenInterceptor = (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      let token = localStorage.getItem("serviceToken");
      const userID = localStorage.getItem("sub");

      if (token) {
        const decoded = decodeToken(token); // Use the custom decode function
        const currentTime = Math.floor(Date.now() / 1000);
        if ((decoded?.exp ?? 0) < currentTime) {
          // Token is expired, refresh it
          const auth = useAuth(); // Make sure this usage is correct

          try {
            token = await auth.refreshToken(); // Refresh the token
            localStorage.setItem("serviceToken", token); // Update local storage with new token
          } catch (error) {
            console.error(error);
            auth.logout();
          }
        }
        config.headers["Authorization"] = `${token}`; // Ensure this is the correct auth header format
        config.headers["userID"] = userID;
      }

      return config;
    },
    (error) => {
      // Handle request error here
      return Promise.reject(error);
    }
  );
};
