import useAuth from "hooks/useAuth";

////
// --- API VALUES
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL; //"http://localhost:3306/"
const BASE_API_URL_LAMBDA = process.env.REACT_APP_BASE_API_URL_LAMBDA;
//"https://0rnrpahby8.execute-api.ap-southeast-2.amazonaws.com/default/"

// const serviceToken = localStorage.getItem('serviceToken');

// --- API CONFIG
const API_BASE_CONFIG = {
  headers: {
    // "Access-Control-Allow-Origin": "*", // Required for CORS support to work
    // "Access-Control-Allow-Credentials": true,// Required for cookies, authorization headers with HTTPS
    // "Access-Control-Allow-Headers": "Content-Type",
    // 'Content-Type': 'application/json',
    "X-Requested-With": "XMLHttpRequest",
  },
  timeout: 0, // default is `0` (no timeout)
  withCredentials: false, // default
  // // auth: {

  // // },
  responseType: "json", // default
  responseEncoding: "utf8", // default
  xsrfCookieName: "XSRF-TOKEN", // default
  xsrfHeaderName: "X-XSRF-TOKEN", // default

  // // browser only
  // // onUploadProgress: function (progressEvent) {
  // //     // Do whatever you want with the native progress event
  // // },
  // // onDownloadProgress: function (progressEvent) {
  // //     // Do whatever you want with the native progress event
  // // },

  maxContentLength: 2000,
  maxBodyLength: 2000,
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },

  // maxRedirects: 5, // default
  // // httpAgent: new http.Agent({ keepAlive: true }),
  // // httpsAgent: new https.Agent({ keepAlive: true }),

  // // proxy: {
  // //     protocol: 'https',
  // //     host: '127.0.0.1',
  // //     port: 9000,

  // // },
  // decompress: true // default
};

const API_CONFIG_GET = {
  // preset
  ...API_BASE_CONFIG,
  method: "get",
  // to set
  // url: '',
  // params: {
  // },
};

const API_CONFIG_POST = {
  // preset
  ...API_BASE_CONFIG,
  method: "post",
  // to set
  url: "",
  params: {},
  data: {},
};

const API_CONFIG_PUT = {
  // preset
  ...API_BASE_CONFIG,
  method: "put",
  // to set
  url: "",
  params: {},
  data: {},
};

const API_CONFIG_DEL = {
  // preset
  ...API_BASE_CONFIG,
  method: "delete",
  // to set
  url: "",
  params: {},
  data: {},
};

const API_CONFIG_PATCH = {
  // preset
  ...API_BASE_CONFIG,
  method: "patch",
  // to set
  url: "",
  params: {},
  data: {},
};

// const AuthHeader = () => {
//     const { session } = useAuth();
//     ;
//     return {
//         'X-Requested-With': 'XMLHttpRequest',
//         'Authorization': session
//     }
// }

export {
  BASE_API_URL_LAMBDA,
  BASE_API_URL,
  API_CONFIG_GET,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  API_CONFIG_DEL,
  API_CONFIG_PATCH,
};
