// routing
import Routes from "routes";

// // project imports
// import Locales from 'components/old/Locales';
// import NavigationScroll from 'layout/NavigationScroll';
// import Snackbar from 'components/old/extended/Snackbar';
// import ThemeCustomization from 'themes';

// project imports
import Locales from "components/old/Locales";
import NavigationScroll from "layout/NavigationScroll";
// import Snackbar from "components/old/extended/Snackbar";
import ThemeCustomization from "themes";

// auth provider
import { AWSCognitoProvider as AuthProvider } from "contexts/AWSCognitoContext";
import { useNavigate } from "react-router-dom";
// snackbar
import { SnackbarProvider } from "notistack";
import ErrorPage from "on-load/ErrorPage";
import OfflineDetection from "on-load/OfflineDetection";
import RQProvider from "on-load/RQProvider";
import { ErrorBoundary } from "react-error-boundary";

// dnd
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// ==============================|| APP ||==============================//

const App = () => {
  const navigate = useNavigate();
  return (
    <AuthProvider>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => {}}
        onError={(error, info) => {
          console.error("Error code:", error);
        }}
      >
        <ThemeCustomization>
          {/* RTL layout */}
          <Locales>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <NavigationScroll>
                <RQProvider>
                  <DndProvider backend={HTML5Backend}>
                    <SnackbarProvider
                      maxSnack={4}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <>
                        <OfflineDetection />
                        <Routes />
                      </>
                    </SnackbarProvider>
                  </DndProvider>
                </RQProvider>
              </NavigationScroll>
            </LocalizationProvider>
          </Locales>
        </ThemeCustomization>
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default App;
