import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { useQuery } from "@tanstack/react-query";
import { Grid, Stack } from "@mui/joy";
import { Divider } from "@mui/material";
import AgXTeam from "assets/images/AgXTeam.png";

const SuccessfulPayment = (props) => {
  const { open, setOpen } = props;

  // Should I show?

  // Get the property. If whatsNewCounter

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            We have recieved your payment 🎉
          </Typography>
          <Box sx={{ backgroundColor: "#f2f2f2", width: "60vw", borderRadius: 10 }}>
            <Stack>
              <Box
                sx={{
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  m: 4,
                }}
              >
                <img src={AgXTeam} alt="Company Logo" width={"50%"} />;
              </Box>
              <Box
                sx={{
                  width: "90%",
                  m: 4,
                }}
              >
                <Typography level="h4" sx={{ ml: 1, mr: 1 }}>
                  Thank you!
                </Typography>
                <Typography level="body-md" sx={{ ml: 1, mr: 1 }}>
                  We are happy that you have decided to use AgX. We are constantly improving the
                  site and are dedicated to the best customer support possible. If you have any
                  issues at all, feel free to call <b>0420942684</b> or email{" "}
                  <b>support@agxfarm.com</b>.
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button variant="solid" color="primary" onClick={() => setOpen(false)}>
              Great!
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default SuccessfulPayment;
