import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { useQuery } from "@tanstack/react-query";
import { Grid, Stack } from "@mui/joy";
import { Divider } from "@mui/material";
import AgMesh from "assets/images/AgMesh.png";

const WhatsNewManager = (props) => {
  const { open, setOpen } = props;

  // Should I show?

  // Get the property. If whatsNewCounter

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Whats New At AgX 🎉
          </Typography>
          <Box sx={{ backgroundColor: "#f2f2f2", width: "60vw", borderRadius: 10 }}>
            <Grid container sx={{}}>
              <Grid
                xs={5}
                sx={{
                  display: "flex",
                }}
              >
                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      m: 4,
                    }}
                  >
                    <img src={AgMesh} alt="Company Logo" width={"50%"} />;
                  </Box>
                  <Typography level="h4" sx={{ ml: 1, mr: 1 }}>
                    Reports have launched
                  </Typography>
                  <Typography level="body-md" sx={{ ml: 1, mr: 1 }}>
                    Look in the reports tab for a historical look at your property. See everything
                    from your livestock to your paddocks.
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={1}></Grid>
              {/* <Divider orientation="vertical" flexItem /> */}

              <Grid
                xs={5}
                sx={{
                  display: "flex",
                }}
              >
                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      m: 4,
                    }}
                  >
                    <img src={AgMesh} alt="Company Logo" width={"50%"} />;
                  </Box>
                  <Typography level="h4" sx={{ ml: 1, mr: 1 }}>
                    New dashboard
                  </Typography>
                  <Typography level="body-md" sx={{ ml: 1, mr: 1 }}>
                    Configure the dashboard to show the information you want to see. In the near
                    future, we will be adding more third-pary widgets.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button variant="solid" color="primary" onClick={() => setOpen(false)}>
              Great!
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default WhatsNewManager;
