import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link } from "@mui/material";

// project imports
import { ROOT_PATH } from "config";
import Logo from "assets/logo/Logo";
import LogoMini from "assets/logo/LogoMini";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ mini = false }) => (
  <Link component={RouterLink} to={ROOT_PATH}>
    {mini ? <LogoMini /> : <Logo />}
  </Link>
);

export default LogoSection;
