// project imports
import { useSnackbar } from "notistack";
import { Detector } from "react-detect-offline";

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const OfflineDetection = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Detector
      render={({ online }) => null}
      onChange={(online) => {
        if (online) {
          closeSnackbar()
        } else {
          enqueueSnackbar('Lost internet connection!', {
            variant: 'error',
            persist: true,
            preventDuplicate: true, anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            }
          })
        }
      }}
    />

  )
};


export default OfflineDetection;
