// icon:baby-bottle | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import { SvgIcon } from "@mui/material";
import * as React from "react";

function IconCowBody(props) {
  return (
    // <SvgIcon {...props}>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="24" height="24"
      viewBox="0 0 512.000000 512.000000"
      fill={props.color || 'currentColor'}
    >

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M682 4400 c-29 -4 -65 -16 -80 -26 -15 -10 -41 -17 -59 -15 -36 3
-101 -23 -165 -66 -61 -41 -93 -53 -141 -53 -40 0 -43 -3 -66 -45 -14 -27 -33
-47 -49 -53 -22 -7 -30 -24 -64 -133 -35 -111 -40 -142 -49 -295 l-11 -170 60
-345 c107 -611 107 -615 36 -1058 -5 -31 -4 -42 4 -37 7 4 30 -21 58 -64 l47
-71 -17 -57 c-32 -106 -32 -100 -8 -79 24 22 24 22 42 -93 16 -103 20 -114 33
-101 19 19 26 12 33 -34 8 -54 19 -58 31 -9 4 20 13 37 18 37 14 3 156 -418
195 -578 18 -71 35 -167 38 -213 8 -132 -2 -127 251 -127 151 0 213 3 222 12
18 18 -35 98 -101 151 -30 25 -60 56 -66 71 -20 44 -65 259 -80 376 -19 150
-18 317 2 386 8 30 44 106 79 169 73 132 106 212 164 397 23 72 48 139 57 147
28 28 58 17 150 -57 49 -40 114 -83 144 -96 30 -13 129 -37 220 -53 l165 -30
250 9 c355 12 386 11 418 -10 41 -26 56 -65 72 -189 51 -366 81 -509 132 -628
63 -143 61 -312 -3 -396 -29 -38 -35 -55 -40 -117 -6 -63 -4 -75 14 -97 20
-25 22 -25 184 -26 89 0 201 -7 248 -14 120 -18 261 -11 289 14 42 38 19 119
-43 146 -82 37 -95 55 -106 150 -6 53 -6 116 0 165 13 115 136 621 165 680 38
77 80 122 174 182 47 30 104 74 127 97 22 22 59 53 82 69 51 32 72 66 112 172
89 235 121 287 265 432 68 69 117 110 149 124 47 20 49 20 164 4 118 -17 215
-15 256 7 11 6 59 11 107 12 81 1 91 4 165 42 l79 42 -2 77 c-2 85 -18 127
-115 289 -45 77 -53 97 -56 150 -4 82 4 93 60 77 77 -22 122 11 131 96 3 28
19 77 36 109 38 69 39 93 6 132 -42 50 -95 65 -202 57 l-91 -7 -25 37 c-14 20
-41 48 -61 61 -22 15 -39 37 -44 55 -7 29 -16 35 -115 71 -89 34 -105 43 -96
54 22 27 1 25 -100 -6 -91 -28 -101 -33 -101 -55 0 -29 -24 -55 -51 -55 -11 0
-38 -14 -59 -31 -37 -30 -41 -31 -107 -24 -61 6 -75 4 -128 -20 -41 -18 -89
-30 -150 -36 -49 -4 -121 -17 -159 -28 -147 -43 -270 -37 -395 18 -63 28 -75
30 -251 35 -102 4 -214 11 -250 16 -36 6 -123 15 -195 21 -71 5 -157 12 -189
15 -34 4 -85 0 -120 -7 -95 -21 -168 -14 -341 29 -207 52 -322 75 -580 112
-193 27 -216 33 -280 66 -65 34 -77 37 -170 39 -55 2 -124 -1 -153 -5z m2275
-3161 c15 -113 15 -266 -1 -304 l-12 -30 -18 70 c-10 39 -20 108 -23 155 -6
94 11 204 30 197 7 -2 17 -41 24 -88z"/>
      </g>
    </svg>
    // </SvgIcon>
  );
}

export default IconCowBody;
