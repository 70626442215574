// ----------------- //
//  ACCOUNT API
// ----------------- //
// Account API requests

// --- IMPORTS
// axios
import axios from "axios";
// react-query
import {
  API_CONFIG_DEL,
  API_CONFIG_GET,
  API_CONFIG_PATCH,
  API_CONFIG_POST,
  API_CONFIG_PUT,
  BASE_API_URL,
} from "api-config/api-helper";
import { applyAuthTokenInterceptor } from "api-config/api-helper/axios_interceptor";

// --- CONFIG
//
const LCL_URL = "account";
const axiosInstance = axios.create({
  baseURL: BASE_API_URL + LCL_URL,
});

applyAuthTokenInterceptor(axiosInstance);

// --- REQUESTS
// Get Account Property List

// KEY: currLivestockData
const api_get_account_mobs = ({ accountID, query }) => {
  const FNC_URL = "mob/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// AcPaddockMap, accountID
const api_get_account_paddock_map = ({ accountID }) => {
  const FNC_URL = "/paddockMap/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// KEY: currLivestockDataFM
const api_get_account_mobsFM = ({ accountID, query }) => {
  const FNC_URL = "mobFM/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: accountBreeds
const api_get_account_breeds = ({ accountID, query = {} }) => {
  const FNC_URL = "breeds/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: propertyList
const api_get_account_properties = ({ accountID }) => {
  const FNC_URL = "properties/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => {
      return res.data;
    });
};

// Get Account Data
// KEY: accountData
const api_get_account_data = ({ accountID }) => {
  const FNC_URL = accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// Get Account onboards
// KEY: pendingUsers
const api_get_account_onboard_rqsts = ({ accountID }) => {
  const FNC_URL = "onboard/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// Get Account Data
// KEY: userInfo
const api_get_account_user_info = ({ accountID }) => {
  const FNC_URL = "user_info/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
    })
    .then((res) => res.data);
};

// KEY: livestockTreatment
const api_get_account_livestock_treatment = ({ accountID, query }) => {
  const FNC_URL = "livestock_treatments/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// KEY: paddockTreatment
const api_get_account_paddock_treatment = ({ accountID, query }) => {
  const FNC_URL = "field_treatments/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      params: {
        ...query,
      },
    })
    .then((res) => res.data);
};

// const api_get_account_livestock_treatment = ({ accountID, query }) => {
//     const FNC_URL = 'livestock_treatments/' + accountID
//     return axiosInstance.request({
//         ...API_CONFIG_GET,
//         url: FNC_URL,
//         params: {
//             ...query
//         }
//     }).then((res) => res.data);
// }

// Get Account List
// const api_get_account_list = ({ userID }) => {
//     const FNC_URL = 'list/' + userID
//     return axiosInstance.request({
//         ...API_CONFIG_GET,
//         url: FNC_URL,
//     }).then((res) => res.data);
// }

// Add Account
// KEY: postAccount
const api_post_account = ({ body }) => {
  const FNC_URL = "";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// Update Account
// KEY: putAccount
const api_put_account = ({ accountID, body }) => {
  const FNC_URL = accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// Patch Account
// KEY: updateAccountData
const api_patch_account = ({ accountID, body }) => {
  const FNC_URL = "info/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_PATCH,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// Del Account
// KEY: delAccount
const api_del_account = ({ accountID, body }) => {
  const FNC_URL = accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_DEL,
      url: FNC_URL,
      // query parameters
      params: {},
      // body
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// livestock dse
// KEY: accountDSE
const api_get_account_dse = ({ accountID }) => {
  const FNC_URL = "/dse_settings/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// KEY: updateAccountDSE
const api_put_account_dse = ({ accountID, body }) => {
  const FNC_URL = "/dse_settings/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      // query parameters
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// account settings
// KEY: acccountSettings
const api_get_account_settings = ({ accountID }) => {
  const FNC_URL = "/settings/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// KEY: updateAccountSettings
const api_put_account_settings = ({ accountID, body }) => {
  const FNC_URL = "/settings/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_PUT,
      url: FNC_URL,
      // query parameters
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// devices
// KEY: propertyDevices
const api_get_account_devices = ({ accountID, propertyID }) => {
  const FNC_URL = "/devices/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {
        property_id: propertyID,
        is_show_stats: true,
      },
    })
    .then((res) => res.data);
};

// REFERALS
// KEY: referalCount, ACCOUNTID
const api_get_account_referal_code = ({ accountID }) => {
  const FNC_URL = "/referal/get_referal_count/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
    })
    .then((res) => res.data);
};

// KEY: useShareCode
const api_post_account_use_share_code = ({
  shareCode,
  accountID,
  email,
  phoneNumber,
  usersName,
  refererAccountID,
}) => {
  const FNC_URL = "/referal/add_share_code/" + shareCode + "/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // query parameters
      params: {},
      data: {
        email,
        phoneNumber,
        usersName,
        refererAccountID,
      },
    })
    .then((res) => res.data);
};

// KEY: postReferalInvite
const api_post_referal_invite = ({ body }) => {
  const FNC_URL = "/referal/send_invite";
  return axiosInstance
    .request({
      ...API_CONFIG_POST,
      url: FNC_URL,
      // query parameters
      params: {},
      data: {
        ...body,
      },
    })
    .then((res) => res.data);
};

// KEY: postReferalInvite
const api_get_referal_code_account = ({ referalCode }) => {
  const FNC_URL = "/referal/get_account_referal_code/" + referalCode;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
      body: {},
    })
    .then((res) => res.data);
};

// KEY: postReferalInvite
const api_get_referal_log = ({ accountID }) => {
  const FNC_URL = "/referal/get_log/" + accountID;
  return axiosInstance
    .request({
      ...API_CONFIG_GET,
      url: FNC_URL,
      // query parameters
      params: {},
      body: {},
    })
    .then((res) => res.data);
};

export {
  api_get_account_properties,
  api_get_account_data,
  api_get_account_user_info,
  api_post_account,
  api_put_account,
  api_del_account,
  api_patch_account,
  api_get_account_mobs,
  api_get_account_mobsFM,
  api_get_account_livestock_treatment,
  api_get_account_onboard_rqsts,
  api_get_account_paddock_treatment,
  api_get_account_dse,
  api_get_account_settings,
  api_put_account_settings,
  api_put_account_dse,
  api_get_account_devices,
  api_get_account_breeds,
  api_get_account_paddock_map,

  // REFERALS
  api_get_account_referal_code,
  api_get_referal_code_account,
  api_post_account_use_share_code,
  api_post_referal_invite,
  api_get_referal_log,
};
