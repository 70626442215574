import React, { Ref } from "react";

// material-ui
import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
  // IconButton,
  Paper,
  Stack,
  Tooltip,
  // Typography,
} from "@mui/material";
// joy-ui
import { Typography, IconButton } from "@mui/joy";
import { useTheme } from "@mui/material/styles";

// MUI ICON IMPORTS
import Fullscreen from "@mui/icons-material/Fullscreen";
import FullscreenExit from "@mui/icons-material/FullscreenExit";
// ---

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { KeyedObject } from "types";

// constant
const denseTitleSX = {
  "& .MuiCardHeader-action": { mr: 0 },
  paddingLeft: 1,
  paddingRight: 5,
  paddingTop: 2,
  paddingBottom: 0,
};

const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
  paddingLeft: 2,
  paddingRight: 5,
  paddingTop: 2,
  paddingBottom: 1,
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps["sx"];
  darkTitle?: boolean;
  sx?: CardProps["sx"];
  secondary?: CardHeaderProps["action"];
  sideTitle?: CardHeaderProps["action"];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  denseTitle?: boolean;
}

const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      sideTitle,
      shadow,
      sx = {},
      title,
      denseTitle = false,
      divider = true,
      variant = "h3",
      enableFullscreen = true,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const handleFS = useFullScreenHandle();

    const handleFullscreen = () => {
      handleFS?.active ? handleFS?.exit() : handleFS?.enter();
    };

    return (
      <FullScreen handle={handleFS}>
        <Card
          ref={ref}
          {...others}
          sx={{
            border: border ? "1px solid" : "none",
            borderColor:
              theme.palette.mode === "dark"
                ? theme.palette.background.default
                : theme.palette.primary[200] + 75,
            ":hover": {
              boxShadow: boxShadow
                ? shadow ||
                  (theme.palette.mode === "dark"
                    ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                    : "0 2px 14px 0 rgb(32 40 45 / 8%)")
                : "inherit",
            },
            backgroundColor: "#FAFAFB",
            ...sx,
          }}
        >
          {/* card header and action */}
          {title && (
            <CardHeader
              sx={denseTitle ? denseTitleSX : headerSX}
              title={
                darkTitle ? (
                  <Typography variant={variant}>
                    <>{title}</>
                  </Typography>
                ) : (
                  <Typography level="title-md" variant={variant}>
                    {title}
                  </Typography>
                )
              }
              action={
                <Stack direction="row" spacing={2}>
                  {secondary}
                  {/* {!!secondary && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        borderRadius: 3,
                        borderColor: "grey.500",
                      }}
                      flexItem
                    />
                  )}
                  <Tooltip title={handleFS?.active ? "Exit Fullscreen" : "Fullscreen"}>
                    <IconButton
                      color={handleFS?.active ? "danger" : "primary"}
                      onClick={handleFullscreen}
                      size="sm"
                    >
                      {handleFS?.active ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </Tooltip> */}
                </Stack>
              }
            />
          )}

          {/* content & header divider */}
          {title && divider && <Divider />}

          {/* card content */}
          {content && (
            <CardContent sx={contentSX} className={contentClass}>
              <Paper
                style={{
                  height: handleFS?.active ? "100vh" : "74vh",
                  overflow: "auto",
                  backgroundColor: "#FAFAFB",
                }}
              >
                {children}
              </Paper>
            </CardContent>
          )}
          {!content && children}
        </Card>
      </FullScreen>
    );
  }
);

export default MainCard;
