import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import App from "App";
import { BASE_PATH } from "config";
import { store } from "store";
import * as serviceWorker from "serviceWorker";
import reportWebVitals from "reportWebVitals";
import { ConfigProvider } from "contexts/ConfigContext";
import { AccountProvider } from "contexts/AccountContext";

// style + assets
import "assets/scss/style.scss";

// SENTRY
import * as Sentry from "@sentry/react";
import userflow from "userflow.js";

import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_API,
//   integrations: [
//     new BrowserTracing(),
//     new Sentry.Replay({
//       maskAllText: false,
//       maskAllInputs: false,
//       blockAllMedia: false,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 1.0,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
// });

// userflow.init(process.env.REACT_APP_USERFLOW_TOKEN || "");

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// ==============================|| REACT DOM RENDER  ||============================== //

root.render(
  <Provider store={store}>
    <ConfigProvider>
      <AccountProvider>
        <BrowserRouter basename={BASE_PATH}>
          <App />
        </BrowserRouter>
      </AccountProvider>
    </ConfigProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
