import { lazy } from "react";

// project imports
import Loadable from "components/old/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// maintenance routing
const MaintenanceError = Loadable(
  lazy(() => import("views/maitenance/maintenance/Error"))
);
// const MaintenanceComingSoon1 = Loadable(lazy(() => import('views/maitenance/maintenance/ComingSoon/ComingSoon1')));
// const MaintenanceComingSoon2 = Loadable(lazy(() => import('views/maitenance/maintenance/ComingSoon/ComingSoon2')));
// const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/maitenance/maintenance/UnderConstruction')));

// for auth pages
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/auth-pages/Register"))
);
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/auth-pages/Login"))
);
// const AuthForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
// const AuthCheckMail3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/CheckMail3')));
// const AuthResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
// const AuthCodeVerification3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/CodeVerification3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/pages/error",
      element: <MaintenanceError />,
    },
    // {
    //     path: '/pages/coming-soon1',
    //     element: <MaintenanceComingSoon1 />
    // },
    // {
    //     path: '/pages/coming-soon2',
    //     element: <MaintenanceComingSoon2 />
    // },
    // {
    //     path: '/pages/under-construction',
    //     element: <MaintenanceUnderConstruction />
    // },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
  ],
};

export default AuthenticationRoutes;
